// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_textarea_auto__s-Rkg {
    width: 100%;
    overflow: hidden;
    border: none;
    outline: none;
    border-radius: 10px;
    white-space: pre-line;
    resize: none;
    padding-left: 10px;
    line-height: 1.5;
}
.Textarea_hidden__3INzz {
    opacity: 0;
    padding-left: 10px;
    position: absolute;
    z-index: -100;
    height: 'auto';
}
.Textarea_wrapper__qidZ4 {
    min-height: 32px;
    margin-bottom: 4px;
    position: relative;
    width: 100%;
}
.Textarea_wrapperfullscreen__p21YK {
    position: fixed !important;
    width: 100%  !important;
    height: 100vh  !important;
    display: block  !important;
    left: 0px !important;
    top: 0px !important;
    background: white  !important;
    z-index: 10000 !important;
}
.Textarea_bold__Oxs19 {
    font-weight: 600;
}

`, "",{"version":3,"sources":["webpack://./src/components/TextareaAuto/Textarea.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,0BAA0B;IAC1B,uBAAuB;IACvB,yBAAyB;IACzB,0BAA0B;IAC1B,oBAAoB;IACpB,mBAAmB;IACnB,6BAA6B;IAC7B,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".textarea_auto {\r\n    width: 100%;\r\n    overflow: hidden;\r\n    border: none;\r\n    outline: none;\r\n    border-radius: 10px;\r\n    white-space: pre-line;\r\n    resize: none;\r\n    padding-left: 10px;\r\n    line-height: 1.5;\r\n}\r\n.hidden {\r\n    opacity: 0;\r\n    padding-left: 10px;\r\n    position: absolute;\r\n    z-index: -100;\r\n    height: 'auto';\r\n}\r\n.wrapper {\r\n    min-height: 32px;\r\n    margin-bottom: 4px;\r\n    position: relative;\r\n    width: 100%;\r\n}\r\n.wrapperfullscreen {\r\n    position: fixed !important;\r\n    width: 100%  !important;\r\n    height: 100vh  !important;\r\n    display: block  !important;\r\n    left: 0px !important;\r\n    top: 0px !important;\r\n    background: white  !important;\r\n    z-index: 10000 !important;\r\n}\r\n.bold {\r\n    font-weight: 600;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea_auto": `Textarea_textarea_auto__s-Rkg`,
	"hidden": `Textarea_hidden__3INzz`,
	"wrapper": `Textarea_wrapper__qidZ4`,
	"wrapperfullscreen": `Textarea_wrapperfullscreen__p21YK`,
	"bold": `Textarea_bold__Oxs19`
};
export default ___CSS_LOADER_EXPORT___;
