import { SET_BREADCRUMB, SET_USERBALANCE } from "./actionType";

export const INIT_STATE = {
  breadCrumbItems: {},
  balance: {}
};

const BreadCrumb = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadCrumbItems: action.payload,
      };
    case SET_USERBALANCE:
        return {
          ...state,
          balance: action.payload,
        };
    default:
      return { ...state };
  }
};

export default BreadCrumb;
