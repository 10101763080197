import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { configureStore } from './store/store'
import config from './config'
import { from, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { split } from '@apollo/client'
import {
    ApolloProvider,
    createHttpLink,
    ApolloClient,
    InMemoryCache,
    ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getMainDefinition } from '@apollo/client/utilities'
import ErrorBoundary from './components/ErrorBoundaru'

const { log } = console

const errorLink = onError(({ graphQLErrors, networkError }) => {
    let error = ''

    if (graphQLErrors) {
        graphQLErrors.forEach(
            ({ message, locations, path }) => (error += `${message}\n`)
        )
    }

    if (networkError) error += `${networkError}`

    //store.dispatch(ReduxActions.notifications.addError(error))
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('tocken')
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : '',
        },
    }
})

const apolloauthLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('tocken')
    operation.setContext((context) => ({
        headers: {
            ...context.headers,
            authorization: token ? `${token}` : '',
        },
    }))
    return forward(operation)
})

const httpLink = new HttpLink({ uri: config.apiserver.host })

var uploadLink = createUploadLink({ uri: config.apiserver.host })

const httpAuthLink = apolloauthLink.concat(httpLink).concat(errorLink)

const wsLink = new GraphQLWsLink(
    createClient({
        url: config.apiserver.wsshost,
        connectionParams: {
            authToken: localStorage.getItem('tocken'),
            reconnect: true,
        },
    })
)
const uploadersplitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        //  console.log(definition)
        return (
            definition.kind === 'OperationDefinition' &&
            (definition.name.value === 'UploadFiles' ||
                definition.name.value === 'UploadAvatar' ||
                definition.name.value === 'UploadProjectFiles')
        )
    },
    uploadLink,
    httpAuthLink
)

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        // console.log(definition)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    uploadersplitLink
)

const client = new ApolloClient({
    link: from([splitLink, uploadLink]),
    cache: new InMemoryCache(),
})

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={configureStore({})}>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ApolloProvider>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
)
