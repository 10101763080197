import React, { useEffect } from "react";
import { TranslateContext } from "../../../providers/Translate";
import {
  UploadIcon,
  FailureStatusIcon,
  PaymentErrorIcon,
  PendingIcon,
  StartedIcon,
  SuccessStatusIcon,
} from "../../../assets/images/icons";
import s from "./components.module.scss";
import { Modal } from "reactstrap";

import config from "../../../config";
import {IsDataFile} from "../FileBlock";

export const NewFilePanel = ( { fromUser, canDelete, deleteFile, fileStatus, onHelp, waitingTime, startTranslationFile, fileName, isIdExist, toggleSpeakersMobile, onStartTranslationFile, onCancelTranslationFile, protocolStatus, filesize, OnCreateResume } ) => {
  const { language } = React.useContext(TranslateContext)
  const [speakers, setSpeakers] = React.useState(0)
  const speakersCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [isOpen, setIsOpen] = React.useState(false)
  const [autoproto, setAutoProto] = React.useState(false)

  const processIcons = {
    UPLOADED: <UploadIcon/>,
    SENT: <StartedIcon/>,
    STARTED: <StartedIcon/>,
    PENDING: <PendingIcon/>,
    NOT_PAID: <PaymentErrorIcon/>,
    FAILURE: <FailureStatusIcon/>,
    SUCCESS: <SuccessStatusIcon/>,
  }
  //React.useEffect(() => fileStatus === 'UPLOADED' && setIsOpen(true), [fileStatus])

  useEffect(() => {
    if (isOpen)
    {
      onStartTranslationFile()
    } 
  }, [isOpen])

  return (
    <>
    {!IsDataFile(fileName) && <div className="row mt-3">
      <div className='d-flex col-auto' style={ { maxHeight: 38 }}>
        <div className='d-flex align-items-center me-2'>
          { language.fileStatus[fileStatus] }
          { (fileStatus === 'PENDING' || fileStatus === 'STARTED') && waitingTime.length && <div className='ms-2'>{ Math.ceil(Number(waitingTime[1]) / 60) }</div> }
          { (fileStatus === 'PENDING' || fileStatus === 'STARTED') && <div className='ms-2'>{language['Min']}</div> }
        </div>
        <div className='d-flex align-items-center'>{ processIcons[fileStatus] }</div>
      </div>
      </div>}

      
      <div className="row mt-3">
        
      { fileStatus === 'NOT_PAID' &&
        <div className="col-auto" style={ { width: 128, maxHeight: 38, display: 'flex' } }>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={ () => setIsOpen(true) }
          >
            { language['ContinueTranscript'] }
          </button>
        </div>
      }

      { fileStatus === 'UPLOADED' && !IsDataFile(fileName) &&
        <div className="col-auto" style={ { width: 150, maxHeight: 38, display: 'flex' } }>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={ () => setIsOpen(true) }
          >
            {language['ContinuePickSpeakers']}
          </button>
        </div>
      }

      { fileStatus === 'UPLOADED' && IsDataFile(fileName) &&
        <div className="col-auto" style={ {  maxHeight: 38, display: 'flex' } }>
          <button
            type='button'
            className='btn btn-primary'
            disabled={!!protocolStatus && protocolStatus !== 'FAILURE' && protocolStatus !== 'NOT_EXIST'}
            onClick={ () => { if (OnCreateResume) OnCreateResume() } }
          >
            {'Создать резюме'}
          </button>
        </div>
      }

      {canDelete ? 
      <div className='col-auto'>
        <button
          className='btn btn-outline-secondary'
          onClick={ deleteFile }
        >
          <i className="bx bx-trash font-size-16"/>
        </button>
      </div> : <></>}

   
      
      </div>
    

      <Modal
        isOpen={ isOpen }
        toggle={ () =>
          {
            onCancelTranslationFile()
            setIsOpen(!isOpen) 
          }}
        id="exampleModalScrollable"
        centered
      >
        <div className="modal-body text-center">
          <p className='font-size-18' >{ language["Speakers count"] }</p>
          <div className='d-flex justify-content-center text-center font-size-20'>
            <div className='ms-1 text-uppercase' style={{ wordBreak: "break-all", maxWidth: 400, fontWeight: "bold"  }}>{ language["File"] }{" "}{ fileName }</div>
          </div>
        </div>
        <div className="modal-body  justify-content-center align-items-center text-center">
          <div className='row'>
            <div className='col'>
              { speakersCount.map(( number, idx ) => (
                <button
                  key={ idx }
                  type="button"
                  onClick={ () => setSpeakers(number) }
                  className={ speakers === number ? "btn btn-primary" : "btn btn-outline-primary" }
                  style={ { marginRight: 10, marginBottom: 10 } }
                >
                  { idx + 1 === speakersCount.length ? `${ number }+` : number }
                </button>
              )) } 
            </div>
          </div>
          <div className='row' style={ { marginTop: 50 } }>
            <div className='col'>
              <button
                onClick={ () => setSpeakers(0) }
                className={ speakers === 0 ? "btn btn-secondary" : "btn btn-outline-secondary" }
                style={ { marginRight: 10, marginBottom: 10 } }
              >
                Затрудняюсь ответить
              </button>
            </div>
          </div>
        </div>
        
        {config.ui.protocolButton == 1 ?
        <div className="modal-footer  justify-content-center align-items-center">
          <input type="checkbox" id="speakersCheckbox" checked={autoproto} onClick={ () => setAutoProto(!autoproto) } style={{cursor: 'pointer'}} />
          <label style={{cursor: 'pointer'}} for="speakersCheckbox">{ language["DecryptionWithProtocol"] }</label>
          {/*
          <button
              type="button"
              onClick={ () => {
                setSpeakers(-1)
                startTranslationFile(-1)
                setIsOpen(!isOpen)
              }}
              className="btn btn-primary "
            >
              { language["OneClickDecryption"] }
            </button>
            */}
        </div> : null}
        <div className="modal-footer  justify-content-center align-items-center">
          <button
            type="button"
            onClick={ () => {
              startTranslationFile(speakers, autoproto)
              setIsOpen(!isOpen)
            }}
            className="btn btn-primary "
          >
            { language["ContinueTranscript"] }
          </button>
          <button
            type="button"
            onClick={ () => 
              {
                onCancelTranslationFile()
                setIsOpen(!isOpen)
              } }
            className="btn btn-outline-primary "
          >
            { language["Cancel"] }
          </button>
        </div>
      </Modal>
    </>
  )
}
