import React, { useState, useEffect } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody } from "reactstrap";
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";

//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


import { Link } from 'react-router-dom';

import graphql from "../../graphql/gql"
import { useProfile, useUsers } from "../../Hooks/UserHooks";
import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";

const Profile = () => {
    const { language } = React.useContext(TranslateContext)
    const { setNotify } = React.useContext(NotifyContext);

    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    
    var [f , SetF] = useState("")
    var [i , SetI] = useState("")
    var [o , SetO] = useState("")

    var [telegram , SetTelegram] = useState("")
    var [email, SetEmail] = useState("")

    var [passview, SetPassView] = useState(false)
    var [passview2, SetPassView2] = useState(false)
    var [passview3, SetPassView3] = useState(false)

    var [phone, SetPhone] = useState("")
    
    var [password, SetPassword] = useState("")
    var [passwordnew, SetPasswordNew] = useState("")
    var [passwordnew2, SetPasswordNew2] = useState("")

    var [errors, SetError] = useState([]);
    var [countries, SetCountries]= useState([]);
    var [phoneMask, SetPhoneMask] = useState("+7 (999) 999 99 99")

    var [betaModel, SetBetaModel] = useState(false)

    /*
    set breadcrumbs
    */

    const TogglePasswordInput = (inpNumber) => {
        if (inpNumber == 1)
        {
            SetPassView(!passview)
        }

        if (inpNumber == 2)
        {
            SetPassView2(!passview2)
        }

        if (inpNumber == 3)
        {
            SetPassView3(!passview3)
        }
    }

    const SaveProfile = () => {

        if (userProfile?.id != "")
        {
            var input = {email: email, f: f, i: i, o: o, telegram: telegram, AlterProtoServer: betaModel}
            UpdateUserProfile({variables: {id: userProfile?.id, input : input}})
        }
    }

    const ChangePassword = () => {
        UserChangePasswordByPassword({variables:{
            oldpassword: password,
            newpassword: passwordnew,
            newpassword2: passwordnew2
        }})   
    }

    const ConfirmEmailSend = () => {

        ConfirmEmail({variables:{}})
    }

    useEffect(() => {
        const breadCrumbItems = {
            title: "Личный кабинет",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    
    var { users, user, LoadUser, error: userError, LoadUsers, RegisterUser, LoginUser, TestPhone, UpdateUserProfile, UserChangePasswordByPassword, ConfirmEmail } = useUsers({
        onDone: (operation, data) => {
          if (operation == "login")
          {
              localStorage.setItem("tocken", data.tocken)
              localStorage.setItem("authUser", JSON.stringify(data));
              document.location = "/"
          }

          if (operation == "updateprofile")
          {
            LoadUser({variables: {id : userProfile?.id ?? ""}})
            setNotify({ message: "Профиль обновился!", type: 'success' })
          }

          if (operation == "passwordchanged")
          {
            setNotify({ message: "Пароль успешно изменен!", type: 'success' })
          }

          if (operation == "sendconfirm")
          {
            setNotify({ message: "На почту отправлен запрос для подтверждения!", type: 'success' })
            SaveProfile()
          }
        }
    })

    const [Countries] = useLazyQuery(graphql.queries.CountriesList,
        {
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            SetCountries(data.countries)
          },
          onError: (error) => {
            alert(error.message);
          },
        }
      );

    useEffect(()=>{
        Countries({})
        LoadUser({variables: {id : userProfile?.id ?? ""}})
    }, [userProfile])  

    useEffect(()=>{
        SetEmail(user?.email ?? "")
        SetTelegram(user?.telegram ?? "")
        SetF(user?.f ?? "")
        SetI(user?.i ?? "")
        SetO(user?.o ?? "")
        SetPhone(user?.phone ?? "")
        SetBetaModel(user?.AlterProtoServer ?? false)
    }, [user])

    useEffect(()=>{
        if (userError!="")
        {
            setNotify({ message: userError, type: 'danger' })
        }
    }, [userError])

    useEffect(()=>{
        
    }, [betaModel])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col md={12} lg={12} xl={6}>

                            <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">

                                                                <form className="mt-4 pt-2" action="/index">

                                                                <label >{language["FIO"]}</label>
                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={4} xl={4} style={{marginBottom:20}}>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter F"]} defaultValue="" value={f} onChange={e => SetF(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={4} xl={4} style={{marginBottom:20}}>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter I"]} defaultValue="" value={i} onChange={e => SetI(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={4} xl={4} style={{marginBottom:20}}>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter O"]} defaultValue="" value={o} onChange={e => SetO(e.target.value)} />
                                                                            </Col>
                                                                        </Row>
                                                                </div>

                                                                <label >Email</label>
                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={"text"} className="form-control m-0 pe-5 me-2" id="password-input" placeholder={language["Enter email"]} defaultValue="" value={email} onChange={e => SetEmail(e.target.value)} />
                                                                            {!user?.emailConfirmed ? <button className="btn btn-outline-secondary waves-effect waves-light" type="button" onClick={()=>ConfirmEmailSend() }>{language["Confirm"]}</button> : <></>}
                                                                        </div>
                                                                </div>

                                                                {!user?.emailConfirmed ?
                                                                <Row className="mb-3">
                                                                    <Col md={12}>
                                                                        Введите и сохраните почтовый ящик, нажмите кнопку Подтвердить. На почту придет ссылка для верификации. (если письмо не приходит в папку Входящие, проверьте папку спам.)
                                                                    </Col>
                                                                </Row> : <></>}

                                                                <label >Telegram  ({user?.telegramID ? user?.telegramID : "ID не указан"})</label>
                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter telegram"]} defaultValue="" value={telegram} onChange={e => SetTelegram(e.target.value)} />
                                                                        </div>
                                                                </div>

                                                                <Row className="mb-3">
                                                                    <Col md={12}>
                                                                        В точности скопируйте ваш телеграм - аккаунт соблюдая регистр символов. Собачка перед именем аккаунта не должна вводиться, только имя аккаунта без любых специальных символов.
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        После сохранения Вашего никнейма телеграм перейдите в бот <a target='blank' href="https://t.me/TimelistAI_bot">@TimelistAI_bot</a>, нажмите Start или введите команду /start.
                                                                    </Col>
                                                                </Row>

                                                                <label style={{fontWeight:"bold"}}>Альтернативная модель автопротоколирования (beta)</label>
                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                    <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                                                        <input className="form-check-input" type="checkbox" id="BetaModelChecked" checked={betaModel} onClick={()=>
                                                                            {
                                                                                betaModel = !betaModel
                                                                                SetBetaModel(betaModel)
                                                                                SaveProfile()
                                                                            }} />
                                                                        <label className="form-check-label" htmlFor="BetaModelChecked">{betaModel == true ? "Включена" : "Выключена"}</label>
                                                                    </div>
                                                                </div>

                                                                <label> {language["Country"]} </label>

                                                                <div className="mb-4">
                                                                    <select disabled className="form-select" onChange={(e)=>
                                                                        {
                                                                        var mask = countries?.filter(c=>c.id == e.target.value)
                                                                        if (mask && mask.length>0)
                                                                        {
                                                                            SetPhoneMask(mask[0].PhoneMask)
                                                                        }
                                                                        }}>
                                                                        {countries?.filter((i)=>i.Actived == true)?.map((country, index)=>{
                                                                        return <option value={country.id}>{country.Country_ru}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                    
                                                                    <label>{ language["Phone number"] }</label>
                                                                    <div className="mb-4">
                                                                        <InputMask
                                                                            id="input-phone"
                                                                            mask={phoneMask}
                                                                            value={phone}
                                                                            disabled
                                                                            placeholder={phoneMask}
                                                                            className="form-control input-color"
                                                                            onChange={(e) => SetPhone(e.target.value)}>
                                                                        </InputMask>
                                                                    </div>

                                                                    <div className="mb-3 text-center">
                                                                        <button className="btn btn-primary waves-effect waves-light" type="button" onClick={SaveProfile}>{language["Save"]}</button>
                                                                    </div>

                                                                    <label >{language["Password change"]}</label>
                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={passview ? "text" : "password"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter Old Password"]} defaultValue="" value={password} onChange={e => SetPassword(e.target.value)} />
                                                                            <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={()=>TogglePasswordInput(1)}>
                                                                                {passview ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                            <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={passview2 ? "text" : "password"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter New Password"]} defaultValue="" value={passwordnew} onChange={e => SetPasswordNew(e.target.value)} />
                                                                            <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={()=>TogglePasswordInput(2)}>
                                                                                {passview2 ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                            <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={passview3 ? "text" : "password"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter Repeat Password"]} defaultValue="" value={passwordnew2} onChange={e => SetPasswordNew2(e.target.value)} />
                                                                            <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={()=>TogglePasswordInput(3)}>
                                                                                {passview3 ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                            <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                    <div className="mb-3 text-center">
                                                                        <button className="btn btn-primary waves-effect waves-light" type="button" onClick={ChangePassword}>{language["Change password"]}</button>
                                                                    </div>
                                                                </form>

                                                              
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                            </Card>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Profile;

