import { gql } from '@apollo/client';

const NEW_CHAT_MESSAGE = gql`subscription NewMessage ($fromuser: String!, $touser: String!) {
    newMessage(fromuser: $fromuser, touser: $touser) {id message 
        fromuser {id}
        touser {id}
        files
        readed createdDate updatedDate
    }
}`

const DELETE_CHAT_MESSAGE = gql`subscription DeleteMessage ($fromuser: String!, $touser: String!) {
    deleteMessage (fromuser: $fromuser, touser: $touser) {id message 
        fromuser {id}
        touser {id}
        files
        readed createdDate updatedDate
    }
}`

const NEW_CHAT_MESSAGE_FOR_ME = gql`subscription NewMessageForMe ($touser: String!) {
    newMessageForMe(touser: $touser) {id message 
        fromuser {id}
        touser {id}
        files
        readed createdDate updatedDate
    }
}`

const ON_ORDERS_UPDATE = gql`subscription TransformationDone($userId: String!) {
  transformationDone(userId: $userId) {
    id
    durationSeconds
    file
    filetype
    minutes
    speakers
    speakersData
    leadSpeaker
    cost
    paid
    translation
    uploadResult
    uploadId
    lastCheckAnswer
    summaryAnswer
    protocolAnswer
    uploadLastCheck
    lastCheckStatus
    uploadProtocol
    protocolStatus
    time_code
    version
    uploadProtocolResult
    uploadTime
    transaltedAt
    createdAt
  }
}`
const GENERATION_PROTOCOL_DONE = gql`subscription GenerationProtocolDone($userId: String!) {
  generationProtocolDone(userId: $userId) { id protocolStatus protocolAnswer file }
}`

const convertationToMp3Start = gql`subscription GenerationProtocolDone($userId: String!) {
  convertationToMp3Start(userId: $userId) { filename error userId }
}`

const convertationToMp3Stop = gql`subscription GenerationProtocolDone($userId: String!) {
  convertationToMp3Stop(userId: $userId) { filename error userId }
}`

const subscriptions = {
  NEW_CHAT_MESSAGE,
  NEW_CHAT_MESSAGE_FOR_ME,
  DELETE_CHAT_MESSAGE,
  ON_ORDERS_UPDATE,
  GENERATION_PROTOCOL_DONE,
  convertationToMp3Start,
  convertationToMp3Stop
}
export default subscriptions
