import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import {Speaker} from "./Speaker";
import { TranslateContext } from '../../../providers/Translate';
import { SuccessIcon } from "../../../assets/images/icons";

import config from "../../../config";


export const AnaliticsPanel = ( { getAnalitic, analiticStatus } ) => {
  const { language } = React.useContext(TranslateContext)
  const protocolTitleColor = {
    PENDING: 'text-primary',
    SUCCESS: 'text-success',
    FAILURE: 'text-warning'
  }

  return (
    <>
      <Card className="mb-2" style={{ height: 'auto', minHeight: '70px' }}>
        <CardBody className="p-0">
          <div className="p-3 d-flex justify-content-between align-items-center">
            <b className='m-0 ml-2'>{"Речевая аналитика"}<span style={{color: 'black', marginLeft: '5px'}}>{"(beta)"}</span></b> 
          </div>
        </CardBody>
      </Card>

      {config.ui.protocolButton == 1 && analiticStatus !== 'SUCCESS' ? 
      <>
      <div className='d-flex justify-content-center mt-4 mb-4'> 
        <Button color="primary" onClick={getAnalitic} disabled={!!analiticStatus && analiticStatus !== 'FAILURE' && analiticStatus !== 'NOT_EXIST'}>
          Получить аналитику
        </Button>
      </div> 
      </>
      : null}

      { analiticStatus &&
        <div className='pt-3 pb-3'>
          <div
            className={ `d-flex justify-content-center ${ protocolTitleColor[analiticStatus] }` }>
            { analiticStatus == 'SUCCESS' && "Аналитика готова" }
            { analiticStatus == 'PENDING' && "Формирование аналитики" }
          </div>
          <div className='d-flex justify-content-center pt-3'>
            { analiticStatus === 'PENDING' && <div className="spinner-border text-primary" role="status"/> }
            { analiticStatus === 'SUCCESS' && <SuccessIcon/> }
          </div>
        </div>
      }
    </>
  )
}
