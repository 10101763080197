import React, { useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { TranslateContext } from '../../../providers/Translate'
import s from '../myFiles.module.scss'
import { CloseIcon } from '../../../assets/images/icons'
import { PendingIconGray } from '../../../assets/images/icons'
import EditIcon from '../../../assets/images/edit.svg'
import SaveIcon from '../../../assets/images/save.svg'
import { TextareaAuto } from '../../../components/TextareaAuto/TextareaAuto'
import { TextareaAutoNew } from '../../../components/TextareaAuto/TextareaAutoNew'
import Moment from 'react-moment'
import { Tooltip } from 'reactstrap'

// const { log } = console
export const Tabs = ({
    decryptionList,
    decryptionList2,
    timelist,
    protocolList,
    decryptionChange,
    speakerChange,
    protocolChange,
    deleteProtocolParagraph,
    tabs,
    activeTab,
    setActiveTab,
    addProtocolParagraph,
    speakers,
    unlockButton,
    playAudio,
    searchtext,
    OnAddEmptyParagraph,
    OnDeleteParagraph,
    fullscreen,
    isNotDocument,
}) => {
    const { language } = React.useContext(TranslateContext)
    const [editedKey, SetEditSpeaker] = React.useState(-1)
    const [focusedkey, SetFocusedKey] = React.useState('')

    const [hint1, setHint1] = React.useState('')

    let StartPlayWith = (time) => {
        var h = time.getHours()
        var m = time.getMinutes()
        var s = time.getSeconds()
        playAudio(h, m, s)
        //alert(h+":"+m+":"+s)
    }

    return (
        <div className="card mb-0">
            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-justified">
                {tabs.map((tab, idx) => (
                    <NavItem key={idx}>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                                active: activeTab === tab,
                            })}
                            onClick={() => setActiveTab(tab)}
                        >
                            <span className="d-sm-block">{language[tab]}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab} className="p-xl-4 p-2">
                <TabPane tabId="Decryption" id="about">
                    <div
                        style={{
                            width: '100%',
                            height: fullscreen
                                ? window.innerHeight - 320
                                : '400px',
                            overflow: 'auto',
                        }}
                    >
                        {Object.entries(decryptionList).map(([key, value]) => (
                            <>
                                <div key={key} className={s.message_line}>
                                    {isNotDocument ? (
                                        <div
                                            className={`${s.speaker} text-truncate`}
                                            style={{
                                                width: 200,
                                                fontWeight: speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                                    ? '600'
                                                    : '',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <select
                                                    className="form-control form-select w-auto"
                                                    type="text"
                                                    style={{
                                                        width: 'auto',
                                                        maxWidth: 180,
                                                    }}
                                                    value={value.speaker}
                                                    onChange={(e) => {
                                                        //setInputSpeaker({...inputSpeaker, name: e.target.value}
                                                        unlockButton()
                                                        speakerChange(
                                                            e.target.value,
                                                            key
                                                        )
                                                        SetEditSpeaker(-1)
                                                    }}
                                                >
                                                    <option
                                                        key={1000}
                                                        value={''}
                                                    ></option>
                                                    {speakers?.map(
                                                        (speaker, idx) => (
                                                            <option
                                                                key={idx}
                                                                value={
                                                                    speaker.name
                                                                }
                                                            >
                                                                {speaker.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>

                                            {/*
                  <div style={{textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    {value.speaker}{" : "}
                  </div>
                  */}

                                            {timelist &&
                                            timelist != '' &&
                                            value.speakerTime ? (
                                                <div className="d-flex align-items-center mt-2">
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() =>
                                                            StartPlayWith(
                                                                value.speakerTime
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: 16,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <Moment format="HH:mm:ss">
                                                            {value.speakerTime}
                                                        </Moment>
                                                    </a>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className={s.text}>
                                        <TextareaAutoNew
                                            selectedkey={focusedkey}
                                            SetFocusedKey={SetFocusedKey}
                                            searchtext={searchtext}
                                            isBold={
                                                !!speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                            }
                                            rowindex={key}
                                            speaker={value.speaker}
                                            value={value.text}
                                            OnAddParagraph={(
                                                rowindex,
                                                operation
                                            ) => {
                                                OnAddEmptyParagraph(
                                                    rowindex,
                                                    operation
                                                )
                                            }}
                                            OnRemoveParagraph={(rowindex) => {
                                                OnDeleteParagraph(
                                                    rowindex,
                                                    value
                                                )
                                            }}
                                            onChange={(value) => {
                                                unlockButton()
                                                decryptionChange(value, key)
                                            }}
                                            tab={activeTab}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </TabPane>

                <TabPane tabId="Protocol" id="tasks">
                    <div className="col">
                        <div
                            style={{
                                width: '100%',
                                height: fullscreen
                                    ? window.innerHeight - 320
                                    : '400px',
                                overflow: 'auto',
                                display: 'block',
                            }}
                        >
                            <div
                                className="d-flex"
                                style={{ paddingBottom: 4 }}
                            >
                                <div
                                    className={s.add_paragraph}
                                    onClick={() => {
                                        unlockButton()
                                        addProtocolParagraph('FIRST_PART')
                                    }}
                                >
                                    +
                                </div>
                                <h5 className={s.paragraph_title}>
                                    {'Протокол совещания'}
                                </h5>
                            </div>
                            {protocolList['FIRST_PART'] &&
                                Object.entries(protocolList['FIRST_PART']).map(
                                    ([key, paragraph]) => (
                                        <div className="d-flex" key={key}>
                                            {paragraph.indexOf(
                                                'Ключевые моменты'
                                            ) < 0 ? (
                                                <div
                                                    className={
                                                        s.delete_paragraph
                                                    }
                                                    onClick={() => {
                                                        unlockButton()
                                                        deleteProtocolParagraph(
                                                            key,
                                                            'FIRST_PART'
                                                        )
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </div>
                                            ) : null}

                                            <TextareaAuto
                                                searchtext={searchtext}
                                                isBold={false}
                                                value={paragraph}
                                                onChange={(value) => {
                                                    unlockButton()
                                                    protocolChange(
                                                        value,
                                                        key,
                                                        'FIRST_PART'
                                                    )
                                                }}
                                                tab={activeTab}
                                            />
                                        </div>
                                    )
                                )}

                            <div
                                className="d-flex"
                                style={{ paddingBottom: 4 }}
                            >
                                <div
                                    className={s.add_paragraph}
                                    onClick={() => {
                                        unlockButton()
                                        addProtocolParagraph('SECOND_PART')
                                    }}
                                >
                                    +
                                </div>
                                <div className={s.paragraph_title}>
                                    {'Следующие шаги'}
                                </div>
                            </div>
                            {protocolList['SECOND_PART'] &&
                                Object.entries(protocolList['SECOND_PART']).map(
                                    ([key, paragraph]) => (
                                        <div className="d-flex" key={key}>
                                            <div
                                                className={s.delete_paragraph}
                                                onClick={() => {
                                                    unlockButton()
                                                    deleteProtocolParagraph(
                                                        key,
                                                        'SECOND_PART'
                                                    )
                                                }}
                                            >
                                                <CloseIcon />
                                            </div>
                                            <TextareaAuto
                                                searchtext={searchtext}
                                                isBold={false}
                                                value={paragraph}
                                                onChange={(value) => {
                                                    unlockButton()
                                                    protocolChange(
                                                        value,
                                                        key,
                                                        'SECOND_PART'
                                                    )
                                                }}
                                                tab={activeTab}
                                            />
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </div>
    )
}
