import { Card, CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux'

import React, { useEffect } from 'react'
import { TranslateContext } from '../../providers/Translate'
import { useProfile, useUsers } from '../../Hooks/UserHooks'
import { setBreadcrumb, setUserBalance } from '../../store/actions'

export const BalanceBlock = ({ minutes, orgdata, totalOrders }) => {
    const dispatch = useDispatch()

    const { language } = React.useContext(TranslateContext)
    const { userProfile } = useProfile()

    const { user, LoadUser } = useUsers({ onDone: (operation, data) => {} })

    useEffect(() => {
        LoadUser({ variables: { id: userProfile?.id ?? '' } })
    }, [userProfile])

    useEffect(() => {
        let balance = orgdata?.minutes ?? user?.minutes ?? 0
        dispatch(setUserBalance({ totalOrders: totalOrders, balance: balance }))
    }, [totalOrders, orgdata?.minutes, user?.minutes])

    return (
        <Card>
            <CardBody className="p-0" style={{ height: 'auto' }}>
                <div className="p-2 mt-2">
                    <div className="row text-center">
                        <div className="col-12">
                            <b>{language['Balance']}</b>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-1">
                    <h5 className="mb-1">
                        {orgdata?.minutes ?? user?.minutes ?? 0}
                    </h5>
                    <p className="mb-0 ms-2">{language['Minutes']}</p>
                </div>

                <div className="p-2 mt-2">
                    <div className="row text-center">
                        <div className="col-12">
                            <b>{language['Orders']}</b>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-1 mb-3">
                    <h5 className="mb-1">{totalOrders ?? 0}</h5>
                    <p className="mb-0 ms-2">шт.</p>
                </div>
            </CardBody>
        </Card>
    )
}
