import React, { useEffect, useContext, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import Icon from 'react-eva-icons';
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, UncontrolledAlert } from 'reactstrap';

import AuthSlider from '../Auth/authCarousel';

import { useUsers } from "../../Hooks/UserHooks";
import { useOrg } from '../../Hooks/OrgHooks';
import { usePresets } from '../../Hooks/PresetsHooks'
import {TranslateContext} from "../../providers/Translate";

import graphql from "../../graphql/gql"


const Login = () => {
    const {language} = React.useContext(TranslateContext)

    const [authemil, SetAuthEmail] = useState(false)
    const [authphone, SetAuthPhone] = useState(false)
    const [passchange, SetPassChange] = useState(false)
    const [registration, SetRegistration] = useState(false)

    var [passview, SetPassView] = useState(false)
    var [phone, SetPhone] = useState("")
    var [email, SetEmail] = useState("")
    var [password, SetPassword] = useState("")
    var [errors, SetError] = useState([]);
    var [countries, SetCountries]= useState([]);
    var [phoneMask, SetPhoneMask] = useState("+7 (999) 999 99 99")
    
    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    const TogglePasswordInput = () => {
        SetPassView(!passview)
    }

    var {presets, LoadPresets} = usePresets()

    var { users, error: userError, LoadUsers, RegisterUser, LoginUser, TestPhone } = useUsers({
        onDone: (operation, data) => {
          if (operation == "login")
          {
              localStorage.setItem("tocken", data?.tocken)
              localStorage.setItem("authUser", JSON.stringify(data));
              document.location = "/"
          }
        }
    })

    var {LoadOrg, orgdata, error} = useOrg({
        onDone: (operation, data) => {
        }
    })

    const [Countries] = useLazyQuery(graphql.queries.CountriesList,
        {
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            SetCountries(data?.countries)
          },
          onError: (error) => {
            SetError([error.message])
          },
        }
      );

    const TryLogin = () =>{
        var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")

        if (orgdata?.registerByPhone || authphone)
        {
            LoginUser({ variables: { phone: clearphone, password: password} })
        } 
        else if (orgdata?.registerByEmail || authemil)
        {
            LoginUser({ variables: { phone: email, password: password} })
        } else 
        {
            LoginUser({ variables: { phone: clearphone, password: password} })
        }

    }

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {
            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    useEffect(() => {
          
        var authByEmailItem = presets?.filter(d=>d.name == "authByEmail")
        var authByPhoneItem = presets?.filter(d=>d.name == "authByPhone")
        var passChangeItem = presets?.filter(d=>d.name == "passChange")
        var registrationItem = presets?.filter(d=>d.name == "registration")

        if (authByEmailItem && authByEmailItem.length>0)
        {
            SetAuthEmail(authByEmailItem[0]?.value == 1)
        }

        if (authByPhoneItem && authByPhoneItem.length>0)
        {
            //SetAuthPhone(authByPhoneItem[0]?.value == 1)
        }

        if (passChangeItem && passChangeItem.length>0)
        {
            SetPassChange(passChangeItem[0]?.value == 1)
        }

        if (registrationItem && registrationItem.length>0)
        {
            SetRegistration(registrationItem[0]?.value == 1)
        }

        SetAuthPhone(document.location.host == "i.timelist.ru")

    }, [presets])

    useEffect(()=> {
        LoadPresets({variables: {names: ["authByEmail", "authByPhone", "passChange", "registration"]}})
        Countries({})
    }, [])

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="py-md-5 p-4 auth-bg">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0" style={{maxWidth: 600}}>
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2 py-5">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h3 className="mb-0 fw-semibold text-uppercase">{orgdata?.name || language["Entrence"]}</h3>
                                                                    <p className="text-muted mt-2">{language["Welcome"]}</p>
                                                                </div>

                                                                {userError ?
                                                                    <UncontrolledAlert color="danger">
                                                                        <i className="mdi mdi-block-helper me-2"></i>
                                                                        {userError}
                                                                    </UncontrolledAlert> : null
                                                                }

                                                                {error ? 
                                                                    <UncontrolledAlert color="danger">
                                                                       <i className="mdi mdi-block-helper me-2"></i>
                                                                       {error}
                                                                   </UncontrolledAlert> : null
                                                                }

                                                                <form className="mt-4 pt-2" action="/index">

                                                                <label> {language["Country"]} </label>

                                                                <div className="mb-4">
                                                                    <select className="form-select" onChange={(e)=>
                                                                        {
                                                                            var mask = countries?.filter(c=>c.id == e.target.value)
                                                                            if (mask && mask.length>0)
                                                                            {
                                                                                SetPhoneMask(mask[0].PhoneMask)
                                                                            }
                                                                        }}>
                                                                     
                                                                        {countries?.filter((i)=>i.Actived == true)?.map((country, index)=>{
                                                                           return <option value={country.id}>{country.Country_ru}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                    
                                                                {(orgdata?.registerByPhone ?? false) || authphone ? <>
                                                                    <label>{ language["Phone number"] }</label>
                                                                    <div className="mb-4">
                                                                        <InputMask
                                                                            id="input-phone"
                                                                            mask={phoneMask}
                                                                            value={phone}
                                                                            placeholder={phoneMask}
                                                                            className="form-control input-color"
                                                                            onChange={(e) => SetPhone(e.target.value)}>
                                                                        </InputMask>
                                                                    </div></> : null}

                                                                {(orgdata?.registerByEmail ?? false) || authemil ? <>
                                                                    <label>{ "Email" }</label>
                                                                    <div className="mb-4">
                                                                        <InputMask
                                                                            type={"email"}
                                                                            id="input-email"
                                                                            value={email}
                                                                            placeholder={"Email"}
                                                                            className="form-control input-color"
                                                                            onChange={(e) => SetEmail(e.target.value)}>
                                                                        </InputMask>
                                                                    </div></> : null}

                                                                    <label >{language["Password"]}</label>
                                                                    <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                            <input type={passview ? "text" : "password"} className="form-control m-0 pe-5" id="password-input" placeholder={language["Enter Password"]} defaultValue="" value={password} onChange={e => SetPassword(e.target.value)} />
                                                                            <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={TogglePasswordInput}>
                                                                                {passview ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                            <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-4">
                                                                        <div className="col">
                                                                            <div className="form-check font-size-15">
                                                                                <input className="form-check-input" type="checkbox" id="remember-check" />
                                                                                <label className="form-check-label font-size-13" htmlFor="remember-check">
                                                                                {language["Remember me"]}
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="button" onClick={TryLogin}>{language["Log In"]}</button>
                                                                    </div>
                                                                </form>

                                                                {orgdata?.registerByPhone ?? registration ? 
                                                                <div className="mt-4 text-center">
                                                                    <p className="text-muted mb-0"><Link to="/auth-register"
                                                                        className="text-dark text-underline-on-hover">{language["Register"]}</Link> </p>
                                                                </div> : null}

                                                                {orgdata?.registerByPhone ?? passchange ? 
                                                                <div className="mt-1 pt-1 text-center">
                                                                    <p className="text-muted mb-0"><Link to="/auth-recoverpw"
                                                                        className="text-dark text-underline-on-hover"> {language["Remember password"]}?</Link> </p>
                                                                </div> : null}
                                                                 
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
