module.exports = {
    google: {
        API_KEY: '',
        CLIENT_ID:
            '23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com',
        SECRET: '',
    },
    facebook: {
        APP_ID: '',
    },
    apiserver: {
        host: 'https://api.timelist.ru/api',
        wsshost: 'wss://subscriptions.timelist.ru',
    },
    ui: {
        protocolButton: 1,
    },
}
