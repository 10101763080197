export const PaymentErrorIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8.064 16V18H10.224V16H13.104V14.784H10.224V13.792H12.256C13.0773 13.792 13.776 13.6693 14.352 13.424C14.928 13.168 15.3707 12.8053 15.68 12.336C15.9893 11.856 16.144 11.28 16.144 10.608C16.144 9.95733 15.9893 9.39733 15.68 8.928C15.3707 8.45867 14.928 8.096 14.352 7.84C13.776 7.57333 13.0773 7.44 12.256 7.44H8.064V12.464H6.624V13.792H8.064V14.784H6.624V16H8.064ZM11.904 12.464H10.224V9.168H11.904C12.5973 9.168 13.104 9.30667 13.424 9.584C13.744 9.86133 13.904 10.272 13.904 10.816C13.904 11.36 13.744 11.7707 13.424 12.048C13.104 12.3253 12.5973 12.464 11.904 12.464Z"
          fill="#F9C256"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M1 12C1 6.47715 5.47715 2 11 2C16.5228 2 21 6.47715 21 12C21 17.5228 16.5228 22 11 22C5.47715 22 1 17.5228 1 12ZM11 20C6.58172 20 3 16.4183 3 12C3 7.58172 6.58172 4 11 4C15.4183 4 19 7.58172 19 12C19 16.4183 15.4183 20 11 20Z"
          fill="#F9C256"/>
  </svg>
)
