import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { TranslateContext } from "../../../providers/Translate";

export const ExistFilePanel = ( { fromUser, canDelete, downloadDocs, deleteFile, copyDocument, isOpen, toggle, toggleSpeakersMobile } ) => {
  const { language } = React.useContext(TranslateContext)

  return (
    <>
    <div className="row mt-3">

    {downloadDocs ? 
      <div className='col-4 col-md-auto pr-0 pe-0'>
        <UncontrolledDropdown className="dropdown float-start w-100">
          <DropdownToggle tag="a" className="text-muted dropdown-toggle  w-100">
            <button className='btn btn-sm btn-outline-info mdi mdi-download-outline w-100' style={{ height:30 }}><span style={{ marginLeft:2 }}>Скачать</span></button>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={ () => downloadDocs('DOCX') }>DOCX</DropdownItem>
             {/* <DropdownItem onClick={ () => downloadDocs('XLSX') }>XLSX</DropdownItem> */}
            <DropdownItem onClick={ () => downloadDocs('PDF') }>PDF</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div> : <></>}

      <div className='col-4 col-md-auto px-1'>
                  <button
                    className='btn btn-sm btn-outline-success mdi mdi-content-copy w-100'
                    style={{ height:30 }}
                    onClick={ copyDocument }
                  >
                      <span style={{ marginLeft:2,fontSize:10 }}>Копировать</span>
                  </button>
                </div>

    {canDelete ?
      <div className='col-4 col-md-auto ps-0'>
        <button
          className='btn btn-sm btn-outline-danger w-100' style={{ height:30 }}
          onClick={ deleteFile }>
          <i className="bx bx-trash font-size-16"/>
          <span style={{ marginLeft:2 }}>Удалить</span>
        </button>
      </div> : <></> }

      </div>  

      <div className="row mt-3 d-block d-md-none">
      <div className='col-12 w-100'>
        <button onClick={ () => toggleSpeakersMobile() }
          className={ `btn btn-primary  w-100` }>
          Спикеры
        </button>
        </div>
      </div>

    </>
  )
}
