import {Button, Card, CardBody} from "reactstrap";
import s from "./components.module.scss";
import React, { useEffect } from "react";
import { CrownIcon, CrownFilledIcon, CloseIcon } from "../../../assets/images/icons";
import { NotifyContext } from "../../../providers/Notify";
import { TranslateContext } from "../../../providers/Translate";
import CrossIcon from "../../../assets/images/crossIcon.svg"
import { Tooltip } from 'reactstrap';

export const Speaker = ({speaker, saveNewSpeakerName, changeLeadSpeaker, onDeleteSpeaker}) => {
  const { setNotify } = React.useContext(NotifyContext);
  const { language } = React.useContext(TranslateContext)

  const [inputSpeaker, setInputSpeaker] = React.useState(speaker)
  const [deletehint, setDeleteHint] = React.useState(false)
  const [selectedhint, setSelectedHint] = React.useState(false)
  
  useEffect(()=>{
    setInputSpeaker(speaker)
  }, [speaker])

  function convertToHex(str) {
    var hex = '';
    for(var i=0;i<str.length;i++) {
        hex += ''+str.charCodeAt(i).toString(16);
    }
    return hex;
}

  return (
    <Card className="my-2">
      <CardBody className="p-0">
        <div className="px-3 py-2 flex-row justify-content-between" style={{display: 'flex'}}>
          <input
            className="form-control w-100"
            type="text"
            value={inputSpeaker.name}
            onChange={e => setInputSpeaker({...inputSpeaker, name: e.target.value})}
          />
          <div
            className='ms-2'
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto'}}
          >
            {inputSpeaker.name !== speaker.name &&
              <Button
                className='bx bx-save me-1'
                size="sm"
                outline
                onClick={() => {
                  inputSpeaker.name =  inputSpeaker.name.trim()
                  saveNewSpeakerName(inputSpeaker)
                }}
              />
            }
            <input
              className={s.checkbox}
              id={`selectbtn_${convertToHex(speaker.name)}`}
              type="checkbox"
              onClick={ () => {
                inputSpeaker.name === speaker.name
                  ? changeLeadSpeaker(speaker.initialName)
                  : setNotify({ message: language["SaveSpeaker"], type: 'warning' })
              } }
              checked={!!speaker.role}
              onChange={() => {}}
            />
            <div className={s.icon}>
              {!!speaker.role ? <CrownFilledIcon/> : <CrownIcon/>}
            </div>

            <div id={`crossbtn_${convertToHex(speaker.name)}`} style={{cursor: 'pointer', marginLeft:0, color: '#4A75DA'}} onClick={()=>onDeleteSpeaker(inputSpeaker)}>
               <img src={CrossIcon} />
            </div>

            <Tooltip
                                        placement="bottom"
                                        isOpen={deletehint}
                                        target={`crossbtn_${convertToHex(speaker.name)}`}
                                        toggle={() => {
                                            setDeleteHint(!deletehint);
                                        }}
                                    >
                                      {"Удалить спикера."}
                </Tooltip>

                <Tooltip
                                        placement="bottom"
                                        isOpen={selectedhint}
                                        target={`selectbtn_${convertToHex(speaker.name)}`}
                                        toggle={() => {
                                          setSelectedHint(!selectedhint);
                                        }}
                                    >
                                      {"Выделить текст спикера в расшифровке."}
                </Tooltip>   
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
