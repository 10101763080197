import React from 'react'
import Routes from './routes/index'
import './assets/scss/themes.scss'
import { TranslateProvider } from './providers/Translate'
import { NotifyProvider } from './providers/Notify'

function App() {
    return (
        <React.Fragment>
            <NotifyProvider>
                <TranslateProvider>
                    <Routes />
                </TranslateProvider>
            </NotifyProvider>
        </React.Fragment>
    )
}

export default App
