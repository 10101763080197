import React from "react";
import { TranslateContext } from "../../../providers/Translate";
import { Link } from "react-router-dom";

export const ModalContent = ( {
    contentType,
    onCancel,
    deleteFile,
    splitFile,
    mergeFile,
    idDecryptionAI,
} ) => {
  const { language } = React.useContext(TranslateContext)
  const LOW_BALANCE_PHONE = '+7 495 489 20 10'
  const LOW_BALANCE_TELEGRAM_LINK = 'https://t.me/timelist_talk'

  const modalContent = {
    deleteFile: (
      <div>
        <div className="modal-body text-center">
          <p style={ { fontSize: "18px" } }>{ language["Are you shore delete file"] }</p>
        </div>
        <div className="modal-footer justify-content-center align-items-center">
          <button
            type="button"
            onClick={ deleteFile }
            className="btn btn-primary " style={ { backgroundColor: "orange", color: "white", border: "none" } }
          >
            { language["Delete"] }
          </button>
          <button
            type="button"
            className="btn btn-outline-primary "
            onClick={ onCancel }
          >
            { language["Cancel"] }
          </button>
        </div>
      </div>
    ),
    divideFile: (
      <div>
        <div className="modal-body text-center">
          <p style={{fontSize: "18px"}}>{language["Are you divide this files"]}</p>
        </div>
        <div className="modal-footer  justify-content-center align-items-center">
          <button
            type="button"
            onClick={splitFile}
            className="btn btn-primary "
          >
            {language["Split"]}
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={mergeFile}
          >
            {language["Combine"]}
          </button>
        </div>
      </div>
    ),
    supportInformation: (
      <div style={ { margin: 32 } }>
        <h6 className='d-flex justify-content-center'>{ language.modals['Support1'] }</h6>
        <h6>{ language.modals['Support2'] }</h6>
        <div className='d-flex justify-content-center mt-3'>
          <strong>
            { `${ language.modals['Support3'] } - ${ idDecryptionAI || 0 }` }
          </strong>
        </div>
        <div className='d-flex justify-content-center mt-3'>
          <a href='https://t.me/timelist_talk' target="_blank" style={{marginRight:20}}>
            <button type='button' className='btn btn-outline-primary'>
              @timelist_talk
            </button>
          </a>
          <Link to="/feedback">
            <button type='button' className='btn btn-outline-primary'>
              { language.modals['SupportBTN'] }
            </button>
          </Link>
        </div>
      </div>
    ),
    newFile: (
      <div style={ { margin: 24 } }>
        <h5 className='d-flex justify-content-center text-success'>{ language.modals['NewFile1'] }</h5>
        <div className='d-flex justify-content-center mt-4'><strong>{ language.modals['NewFile2'] }</strong></div>
        <div className='d-flex ms-4'>{ language.modals['NewFile3'] }</div>
        <div className='d-flex ms-4'>{ language.modals['NewFile4'] }</div>
        <div className='d-flex  ms-4'>{ language.modals['NewFile5'] }</div>
        <div className='d-flex justify-content-center mt-3'>
          <button type='button' className='btn btn-primary' onClick={ onCancel }>
            { language.modals['NewFileBTN'] }
          </button>
        </div>
      </div>
    ),
    lowBalance: (
      <div style={ { margin: 24 } }>
        <div className='d-flex justify-content-center'><strong>{ language.modals['LowBalance1'] }</strong></div>
        <div className='d-flex justify-content-center text-center mt-3'>{ language.modals['LowBalance2'] }</div>
        <div className='d-flex justify-content-center text-center text-primary mt-3'>
          <a target="_blank" rel="noreferrer" href={`${LOW_BALANCE_TELEGRAM_LINK}`}>{ LOW_BALANCE_TELEGRAM_LINK }</a>
        </div>
        <div className='d-flex justify-content-center text-center mt-3'>
          { language.modals['LowBalance3'] }
          <div className='text-primary ms-1'>{ LOW_BALANCE_PHONE }</div>
        </div>
      </div>
    )
  }

  return (
    <div className='position-relative'>
      { modalContent[contentType] }
      <div
        style={ { position: 'absolute', top: '5px', right: '12px', cursor: 'pointer', opacity: '0.7' } }
        onClick={ onCancel }
      >
        <button type="button" className="btn-close font-size-10"/>
      </div>
    </div>
  )
}
