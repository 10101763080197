import { useState } from "react";

import { useLazyQuery, useMutation } from '@apollo/client';

import graphql from "../graphql/gql"

export const usePresets = () => {
  const [error, SetError] = useState("");
  const [presets, SetPresets] = useState();

  const onError = ( error ) => {
    SetError(error.message)
    console.log(error)
    setTimeout(() => {
      SetError("")
    }, 2000)
  }

  const [LoadPresets] = useLazyQuery(graphql.queries.PRESETS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => SetPresets(data.presetslist),
    onError,
  })

  return { error, presets, LoadPresets }
}
