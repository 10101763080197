import React from "react";
import s from './Textarea.module.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image', 'video'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']                                         // remove formatting button
];

export const TextareaAuto = ( { value, rowindex, speaker, onChange, isBold, tab, searchtext } ) => {     // do not remove "tab". It's need to resize textarea
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const hiddenElement = React.useRef(null);
  const [height, setHeight] = React.useState(0)
  const [focused, SetFocused] = React.useState(false)
  const [visibletext, SetVisibleText] = React.useState(value)
  const [border, SetBorder] = React.useState("0px solid black")

  function adjustHeight(el){
    if (el)
    {
        el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight)+"px" : "auto";
    }
  }

  const HightLightFileText = (text, substr) => {
    let rg = new RegExp(substr, 'gi');
    return substr!="" ? text.replace(rg, `<span style="color:red; font-weight:bold;">${substr.toUpperCase()}</span>`) : text
  }

  React.useEffect(() => 
  {
    if (hiddenElement && hiddenElement?.current)
    {
      //adjustHeight(hiddenElement.current)
      hiddenElement.current.style.height = "auto";
      hiddenElement.current.style.minHeight = 30+"px";
    }
    
    //setHeight(hiddenElement.current.clientHeight)
    if (ref && ref?.current)
    {
      adjustHeight(ref.current)
    }
    //adjustHeight(ref2.current)
  }, [tab, focused])

  React.useEffect(() => {
    const textareaRef = ref?.current
    if (!ref && !textareaRef) return

    const autoResize = () => setTimeout(() => {
      //textareaRef.style.cssText = 'height:auto;'
      //textareaRef.style.cssText = `height: ${textareaRef.scrollHeight}px`
    }, 0)
    
    ref?.current?.focus()

    ref?.current?.addEventListener('input', autoResize);
    return () => textareaRef?.removeEventListener('input', autoResize)
  }, [height, tab, focused])

  React.useEffect(()=>{
    SetVisibleText(value)
  }, [value])

  return (
    <div className={ s.wrapper }>
      
      {!focused ? 
      <div onClick={()=>
      {
        if (value.indexOf("Ключевые моменты")<0)
        {
            SetFocused(true)
        }
      }} ref={ hiddenElement } className={`${s.textarea_auto} ${isBold ? s.bold : ''}`} dangerouslySetInnerHTML={{__html: HightLightFileText(visibletext, searchtext)}}>
      </div> :
     <textarea
        rows={1}
        ref={ ref }
        className={`${s.textarea_auto} ${isBold ? s.bold : ''}`}
        style={{ height : height, border: border }}
        value={ value }
        onFocus={ e => {
          SetBorder("1px solid black")
          SetFocused(true)
        }}
        onBlur={ e => {
          SetBorder("0px solid black")
          SetFocused(false)
        }}
        onChange={ e => {
          if (e.nativeEvent.inputType === "insertLineBreak") return
          onChange(e.target.value)
          SetVisibleText(e.target.value)
        } }
      /> }

    </div>
  )
}
