import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Form, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody, CardHeader } from "reactstrap";

import Dropzone from "react-dropzone";

import classnames from "classnames";
import MetaTags from "react-meta-tags";


//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import Img1 from "../../assets/images/post-1.jpg";
import Img2 from "../../assets/images/post-2.jpg";

import bgimg from "../../assets/images/pattern-bg.jpg";
import { Link } from 'react-router-dom';
import {TranslateContext} from "../../providers/Translate";

const Wallet = () => {
    const {language} = React.useContext(TranslateContext)
    const dispatch = useDispatch();

    var [balance, SetBalance] = useState(100);
    var [minutes, SetMinutes] = useState(0);
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: language["Wallet"],
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    useEffect(()=>{
        SetMinutes(Math.round(balance/5))
    }, [balance])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Profile | Borex - React Admin & Dashboard Template</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col className="col-xxl-3">
                            <div className="user-sidebar">
                                <Card>
                                    <CardBody className="p-0">
                                        <div className="user-profile-img">
                                            <img src={bgimg}
                                                className="profile-img profile-foreground-img rounded-top" style={{ height: "120px" }}
                                                alt="" />
                                            <div className="overlay-content rounded-top">
                                                <div>
                                                    <div className="user-nav p-3">
                                                        <div className="d-flex justify-content-end">
                                                            <UncontrolledDropdown className="dropdown">
                                                                <DropdownToggle tag="a" className="btn btn-link text-muted py-1 font-size-16 shadow-none">
                                                                    <i data-eva="settings-2-outline" data-eva-width="20" data-eva-height="20" className="fill-white"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem>Action</DropdownItem>
                                                                    <DropdownItem>Another action</DropdownItem>
                                                                    <DropdownItem>Something else here</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="mt-n5 position-relative">
                                            <div className="text-center">
                                                <img src={avatar1} alt=" "
                                                    className="avatar-xl rounded-circle img-thumbnail" />

                                                <div className="mt-3">
                                                    <h5 className="mb-1">Jennifer Bennett</h5>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="p-3 mt-3">
                                            <div className="row text-center">
                                                <div className="col-12">
                                                    <b>{language["Balance"]}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-3 mt-3">
                                            <div className="row text-center">
                                                <div className="col-6 border-end">
                                                    <div className="p-1">
                                                        <h5 className="mb-1">{balance}</h5>
                                                        <p className="text-muted mb-0">{language["Rubles"]}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="p-1">
                                                        <h5 className="mb-1">{minutes}</h5>
                                                        <p className="text-muted mb-0">{language["Minutes"]}</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </CardBody>

                                </Card>





                            </div>
                        </Col>

                        <div className="col-xxl-9">

                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                    <div className="col-6">
                                        <h1>{language["Wallet"]}</h1>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button type="button" className="btn btn-primary">{language["Upload file"]}</button>
                                    </div>
                                    </div>
                                </div>
                                <div className="card-body">



                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Wallet;
