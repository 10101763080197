import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import {Speaker} from "./Speaker";
import { TranslateContext } from '../../../providers/Translate';
import { SuccessIcon } from "../../../assets/images/icons";

import config from "../../../config";


export const SpeakersPanel = ( { speakers, newSpaekerAwait, saveNewSpeakerName, getProtocol, changeLeadSpeaker, addNewSpeaker, protocolStatus, onDeleteSpeaker, editSpeakerName, onStartEditing } ) => {
  const { language } = React.useContext(TranslateContext)
  const protocolTitleColor = {
    PENDING: 'text-primary',
    SUCCESS: 'text-success',
    FAILURE: 'text-warning',
  }

  if (!speakers.length) return <></>

  return (
    <>
      <Card className="mb-2">
        <CardBody className="p-0">
          <div className="p-3 d-flex justify-content-between align-items-center">
            <b className='m-0 ml-2'>{language["Speakers"]} ({speakers.length})</b>
            <button className='btn btn-outline-secondary' onClick={addNewSpeaker}><i className="fas fa-plus"/></button>
          </div>
        </CardBody>
      </Card>

      {speakers.map((speaker, idx) => (
        <Speaker
          onStartEditing = {onStartEditing}
          editSpeakerName = {editSpeakerName}
          key={idx}
          speaker={speaker}
          saveNewSpeakerName={saveNewSpeakerName}
          changeLeadSpeaker={changeLeadSpeaker}
          onDeleteSpeaker={onDeleteSpeaker}
        />
      ))}

      {config.ui.protocolButton == 1 ? // && protocolStatus != 'SUCCESS' ? 
      <>
      <div className='d-flex justify-content-center mt-4 mb-4'>
        <Button color="primary" onClick={getProtocol} disabled={!!protocolStatus && protocolStatus == 'PENDING'}>
          Получить автопротокол
        </Button>
      </div> 

    {protocolStatus != 'SUCCESS' ?
      <div className='text-muted font-size-12 mb-4'>
        <p>Для улучшения качества Автопротокола сначала</p>
        <ul>
          <li>проверьте расшифровку,</li>
          <li>переименуйте спикеров,</li>
          <li>отметьте тех спикеров, чьи слова наиболее важны (председатель комиссии, руководитель или тот, кто
            резюмирует принятые решения).
          </li>
        </ul>
        {/* <p className='fw-semibold mb-0'>Автопротокол можно получить только 1 раз на каждый файл.</p> */}
      </div> 
      : null}

      </>
      : null}

      { protocolStatus &&
        <div className='pt-3 mb-4'>
          <div
            className={ `d-flex justify-content-center ${ protocolTitleColor[protocolStatus] }` }>
            { language[protocolStatus] }
          </div>
          <div className='d-flex justify-content-center pt-3'>
            { protocolStatus === 'PENDING' && <div className="spinner-border text-primary" role="status"/> }
            { protocolStatus === 'SUCCESS' && <SuccessIcon/> }
          </div>
        </div>
      }
    </>
  )
}
