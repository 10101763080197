import { useEffect, useState } from "react";
import { getLoggedinUser } from "../helpers/api_helper";

import { useLazyQuery, useMutation } from '@apollo/client';

import graphql from "../graphql/gql"

const useOrg = ({onDone}) => {
  const [orgdata, SetOrgData] = useState();
  const [error, SetError] = useState("");

  const [LoadOrg] = useLazyQuery(graphql.queries.ORGDATA, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        SetOrgData(data?.orgbyurl)
        if (onDone) onDone("orgdata", data?.orgbyurl)
    },
    onError(error) {
        SetError(error?.networkError?.result)//JSON.stringify(error))
    },
  });   

  useEffect(()=>{
    var url = window.location.host
    LoadOrg({variables: {url: url}})
  }, [])
  
  return {LoadOrg, orgdata, error}
}

export { useOrg };
