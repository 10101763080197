import React from "react";
import {createContext} from "react";
import Languages from "../langs/languages"

export const TranslateContext = createContext({})

export const TranslateProvider = ({children}) => {
  const [activeLanguage, setActiveLanguage] = React.useState("Russian")

  const language = React.useMemo(() => Languages[activeLanguage], [activeLanguage])

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang)
    setActiveLanguage(lang)
  }

  const value = {
    language: language,
    changeLanguage,
    activeLanguage
  }

  return (
    <TranslateContext.Provider value={value}>
      {children}
    </TranslateContext.Provider>
  )
}
