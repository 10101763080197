import React, { useEffect, useState, useContext } from "react";
import Icon from "react-eva-icons";
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";
import { gql, useLazyQuery, useMutation } from '@apollo/client';

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledAlert,
} from "reactstrap";

import AuthSlider from "./authCarousel";

import { useUsers } from "../../Hooks/UserHooks";
import {useOrg} from "../../Hooks/OrgHooks";

import graphql from "../../graphql/gql"

import errorIcon from "../../assets/images/error.svg";
import successIcon from "../../assets/images/successed.svg";
import {TranslateContext} from "../../providers/Translate";

var startawait = null
const Register = () => {
  const {language} = React.useContext(TranslateContext)

  var [passview, SetPassView] = useState(false);
  var [name, SetName] = useState("");
  var [phone, SetPhone] = useState("");
  var [email, SetEmail] = useState("");
  var [errors, SetError] = useState([]);
  var [countries, SetCountries]= useState([]);
  var [password, SetPassword] = useState("");
  var [formstate, SetFormState] = useState("registration")
  var [phoneForCall, SetPhoneForCall] = useState("")

  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  var {LoadOrg, orgdata, error} = useOrg({
    onDone: (operation, data) => {
    }
  })

  var { users, error: userError, LoadUsers, RegisterUser, TestPhone } = useUsers({
    onDone: (operation, data) => {
      if (operation == "testphone")
      {
        if (data == true)
        {
          // номер свободен, перехожу к ожиданию звонка
          SetFormState("callawait")
          //SetFormState("final")
          
        } else
        {
          // номер занят
          SetError(["Номер занят! Попробуйте другой."])
          setTimeout(() => {
            SetError([])
          }, 2000)
        }
      }

      if (operation == "register")
      {
          localStorage.setItem("tocken", data.tocken)
          localStorage.setItem("authUser", JSON.stringify(data));
          document.location = "/"
      }
    }
  });

  const [PhoneConfirmation] = useMutation(graphql.mutations.sendPhoneConfirmationMutation,
    {
      onCompleted: (data) => {
        clearInterval(startawait)
        //var testphone = "8" + data.awaitcall.substring(1)
        SetPhoneForCall("+"+data.awaitcall)

        var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
        startawait = setInterval(()=>
        {
          PhoneHasCallFrom({variables:{phone:clearphone}})
        }, 5000)
      },
      onError: (error) => {
        alert(error.message);
      },
    }
  );

  const [PhoneHasCallFrom] = useLazyQuery(graphql.queries.phoneConfirmationHasCall,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.hasawaitedcall && data.hasawaitedcall.length>0)
        {
          if (data.hasawaitedcall[0].callback == 1)
          {
              clearInterval(startawait)
              SetFormState("final")
          }
        }
      },
      onError: (error) => {
        alert(error.message);
      },
    }
  );

  const [Countries] = useLazyQuery(graphql.queries.CountriesList,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        SetCountries(data.countries)
      },
      onError: (error) => {
        alert(error.message);
      },
    }
  );

  useEffect(() => {
    if (layoutMode === "dark") {
      document.body.classList.remove("bg-transparent");
    } else {
      document.body.className = "bg-transparent";
    }
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  useEffect(()=>{
    if (formstate == "callawait")
    {
      var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
      PhoneConfirmation({variables:{phone: clearphone}})
    }
  }, [formstate])

  useEffect(()=>Countries({}), [])

  const TryRegister = () => {
    var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
    
    RegisterUser({
      variables: { input: { i: name, phone: clearphone, email: email, password: password } },
    });
  };

  const OnWaitCall = () => {

    var err = []
    var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
    if (orgdata?.registerByPhone == true && clearphone == "" || clearphone.length < 11)
    {
        err.push("Введите номер телефона. Все цифры должны быть заполнены.")
    }

    if (name == "")
    {
        err.push("Введите ваше имя.")
    }

    if (orgdata?.registerByEmail == true && email == "")
    {
        err.push("Введите корректный email адрес.")
    }

    if (err.length>0)
    {
        SetError([...errors, ...err])
        setTimeout(() => {
            SetError([])
        }, 2000)
        return
    }

    // тест телефона
    TestPhone({variables: {phone: clearphone}})
  }

  const OnBackCallOk = () => {
    SetFormState("final")
  }

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="py-md-5 p-4 auth-bg">
          <Row className="g-0 align-items-center">
            <Col className="col-xxl-4 col-lg-4 col-md-6">
              <Row className="justify-content-center g-0">
                <Col xl={9}>
                  <div className="p-4">
                    <Card className="mb-0" style={{ maxWidth: 600 }}>
                      <CardBody>
                        <div className="auth-full-page-content rounded d-flex p-3 my-2 py-5">
                          <div className="w-100">
                            <div className="d-flex flex-column h-100">
                              <div className="auth-content my-auto">
                                <div className="text-center">
                                  { formstate == "final" ? <div className="d-flex align-items-center justify-content-center mb-3">
                                    <img src={successIcon} style={{width:85, height:85}} alt="" />
                                  </div>: null}
                                  <h5 className="mb-0 fw-semibold">
                                    {formstate != "final" ? language["Register"] : language["Success"]}
                                  </h5>
                                  <p className="text-muted mt-2">
                                    {formstate == "registration" ? language["Welcome"] : ""}
                                    {formstate == "callawait" ? language["callawait"] : ""}
                                    {formstate == "final" ? language["RegistartionFinalize"] : ""}
                                  </p>
                                  {formstate == "callawait" ? <a href={`tel: ${phoneForCall}`}><h3 className="mt-5 mb-5 text-center text-primary font-weight-bold">{phoneForCall}</h3></a> : ""}
                                  <p className="text-center px-4">
                                    {formstate == "callawait" ? language["callawait2"] : ""}
                                  </p>
                                  {formstate == "callawait"
                                    ? <p className="mt-5 mb-5">
                                        <div className="mb-2">{language["AwaitingCall"]}</div>
                                        <div className="spinner-border text-primary m-1" role="status">
                                        </div>
                                      </p>
                                    : ""
                                  }
                                </div>

                                {userError ? (
                                  <UncontrolledAlert color="danger">
                                    <i className="mdi mdi-block-helper me-2"></i>
                                    {userError}
                                  </UncontrolledAlert>
                                ) : null}

                                {errors.map((err)=>{
                                   return <UncontrolledAlert color="danger">
                                   <i className="mdi mdi-block-helper me-2"></i>
                                    {err}
                                  </UncontrolledAlert>
                                })}

                                {formstate == "registration" ? (
                                  <RegistrationForm phoneRegister={orgdata?.registerByPhone ?? true} emailRegister={orgdata?.registerByEmail ?? false} name={name} SetName={SetName} phone={phone} email={email} SetEmail={SetEmail} SetPhone={SetPhone} countries={countries} OnWaitCall={OnWaitCall}></RegistrationForm>
                                ) : null}

                                {formstate == "callawait" ? (
                                  <CallAwaitForm SetFormState={SetFormState}></CallAwaitForm>
                                ) : null}

                                {formstate == "final" ? (
                                  <CallBackSuccessForm SetPassword={SetPassword} password={password} TryRegister={TryRegister} SetError={SetError} errors={errors}></CallBackSuccessForm>
                                ) : null}

                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
            <AuthSlider />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const CallAwaitForm = ({SetFormState}) => {
  const {language} = React.useContext(TranslateContext)
    return (
        <>
          <form className="mt-4 pt-2" action="/">
            {/*
            <div className="mb-3">
              <button
                className="btn btn-primary w-100 waves-effect waves-light"
                type="button"
                onClick={()=>{SetFormState("final")}}
              >
                {language["RegisterBtn"]}
              </button>
            </div>
            */}
          </form>

          <div className="mt-4 text-center">
            <p className="text-muted mb-0">
              {language["Wrong number"]}{" "}
              <Link to="#" className="text-dark" onClick={() => {SetFormState("registration")}}>
                {language["Back"]}
              </Link>{" "}
            </p>
          </div>
        </>
      );
}

const CallBackSuccessForm = ({password, SetPassword, TryRegister, SetError, errors}) => {
  const {language} = React.useContext(TranslateContext)

    var [passview, SetPassView] = useState(false)

    const TogglePasswordInput = () => {
        SetPassView(!passview)
    }

    const TryRegisterUser = () =>{

      if (password.length<8)
      {
        SetError([...errors, "Пароль слишком короткий или отсутствует."])
        setTimeout(() => {
            SetError([])
        }, 2000)
      } else
      {
        TryRegister()
      }
    }

    return (<>
          <form className="mt-4 pt-2" action="/">

          <label >{language["Password"]}</label>
          <div className=" mb-4 auth-pass-inputgroup">
              <div className='d-flex align-items-center position-relative'>
                                                                        <input type={passview ? "text" : "password"} className="form-control pe-5" id="password-input" placeholder={language["Enter Password"]} value={password} onChange={e => SetPassword(e.target.value)} />
                                                                        <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={TogglePasswordInput}>
                                                                            {passview ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                        <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                        </button>
              </div>
          </div>

          <div className="row mb-4">
            <div className="col">
                                                                            <div className="form-check font-size-15">
                                                                                <input className="form-check-input" type="checkbox" id="remember-check" />
                                                                                <label className="form-check-label font-size-13" htmlFor="remember-check">
                                                                                {language["Remember me"]}
                                                                                </label>
                                                                            </div>
            </div>
          </div>

            <div className="mb-3">
              <button
                className="btn btn-primary w-100 waves-effect waves-light"
                type="button"
                onClick={()=>{TryRegisterUser()}}
              >
                {language["Done"]}
              </button>
            </div>
          </form>
        </>)

}

const RegistrationForm = ({name, SetName, phone, email, countries, SetPhone, SetEmail, OnWaitCall, phoneRegister, emailRegister}) => {
  const {language} = React.useContext(TranslateContext)
  var [TermsAccepted, SetTermsAccepted] = useState(false);
  var [phoneMask, SetPhoneMask] = useState("+7 (999) 999 99 99")

  return (
    <>
      <form className="mt-4 pt-2" action="/">
        <label htmlFor="input-email">
          {language["Name"]}
        </label>
        <div className="mb-4">
          <input
            type="text"
            className="form-control"
            id="input-email"
            value={name}
            onChange={(e) => SetName(e.target.value)}
            placeholder={language["Enter Name"]}
            required
          />
        </div>

        <label htmlFor="input-username">
          {language["Country"]}
        </label>

        <div className="mb-4">
          <select className="form-select" onChange={(e)=>
            {
              var mask = countries?.filter(c=>c.id == e.target.value)
              if (mask && mask.length>0)
              {
                SetPhoneMask(mask[0].PhoneMask)
              }
            }}>
            {countries?.filter((i)=>i.Actived == true)?.map((country, index)=>{
              return <option value={country.id}>{country.Country_ru}</option>
            })}
          </select>
        </div>

        {phoneRegister ? <>
        <label htmlFor="input-username">
          {language["Phone"]}
        </label>
        <div className="mb-4">
          <InputMask
              id="input-username"
              mask={phoneMask}
              value={phone}
              placeholder={phoneMask}
              className="form-control input-color"
              onChange={(e) => SetPhone(e.target.value)}
          >
          </InputMask>
        </div> </> : null}

        {emailRegister ? <>
        <label htmlFor="input-username">
          {language["Email"]}
        </label>
        <div className="mb-4">
        <input
            type="email"
            className="form-control"
            id="input-email"
            value={email}
            onChange={(e) => SetEmail(e.target.value)}
            placeholder={language["Enter Email"]}
            required
          />
        </div></> : null }

        <div className="row mb-4">
          <div className="col">
            <div className="form-check font-size-15">
              <input
                className="form-check-input"
                type="checkbox"
                id="remember-check"
                defaultChecked={false}
                value={TermsAccepted}
                onChange={(e) => {
                  SetTermsAccepted(e.target.checked);
                }}
              />
              <div
                className="form-check-label font-size-13"
                style={{ paddingLeft: 20 }}
              >
                <p className="mb-0">
                  <label className="form-check-label font-size-13" htmlFor="remember-check">{language["terms1"]}</label>
                  {" "}
                  <a href="https://timelist.ru/policy" className="text-primary" target="_blank">
                    {language["terms2"]}
                  </a>{",  "}
                  <a href="https://timelist.ru/agreement" className="text-primary" target="_blank">
                    {language["terms22"]}
                  </a>{" и "}
                  <a href="https://timelist.ru/license" className="text-primary" target="_blank">
                    {language["terms23"]}
                  </a>{" "}
                  <label className="form-check-label font-size-13" htmlFor="remember-check">{language["terms3"]}</label>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <button
            className="btn btn-primary w-100 waves-effect waves-light"
            disabled={!TermsAccepted}
            type="button"
            onClick={OnWaitCall}
          >
            {language["Accept"]}
          </button>
        </div>
      </form>

      <div className="mt-4 text-center">
        <p className="text-muted mb-0">
          {language["Already registered?"]}{" "}
          <Link to="/login" className="text-dark text-underline-on-hover">
            {language["Login"]}
          </Link>{" "}
        </p>
      </div>
    </>
  );
};

export default Register;
