import { SET_BREADCRUMB, SET_USERBALANCE } from "./actionType";

// common success

export const setBreadcrumb = (breadCrumbItems) => ({
  type: SET_BREADCRUMB,
  payload: breadCrumbItems,
});

export const setUserBalance = ({totalOrders, balance}) => (
  {
    type: SET_USERBALANCE,
    payload: {totalOrders: totalOrders, balance: balance},
  }
)
