import React, { useState, useEffect } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody } from "reactstrap";
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";

//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


import { Link } from 'react-router-dom';

import graphql from "../../graphql/gql"
import { useProfile, useUsers } from "../../Hooks/UserHooks";
import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";

const Jurface = () => {
    const { language } = React.useContext(TranslateContext)
    const { setNotify } = React.useContext(NotifyContext);

    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    
    var [fio , SetFio] = useState("")
    var [orgName, SetOrgName] = useState("")
    var [inn, SetInn] = useState("")
    var [ogrn, SetOgrn] = useState("")
    var [kpp, SetKpp] = useState("")
    var [JurAddress, SetJurAddress] = useState("")
    var [FizAddress, SetFizAddress] = useState("")
    var [bankName, SetBankName] = useState("")
    var [bik, SetBik] = useState("")
    var [cSchet, SetCSchet] = useState("")
    var [rSchet, SetRSchet] = useState("")

    var [telegram , SetTelegram] = useState("")
    var [email, SetEmail] = useState("")
    var [bemail, SetBEmail] = useState("")

    var [passview, SetPassView] = useState(false)
    var [passview2, SetPassView2] = useState(false)
    var [passview3, SetPassView3] = useState(false)

    var [phone, SetPhone] = useState("")
    var [page, SetPage] = useState(1)

    var [errors, SetError] = useState([]);
    var [countries, SetCountries]= useState([]);
    var [phoneMask, SetPhoneMask] = useState("+7 (999) 999 99 99")

    /*
    set breadcrumbs
    */

    const AddNewOrg = () => {

    }

    const SaveOrgData = () => {

        if (userProfile?.id != "")
        {
            //var input = {email: email, f: f, i: i, o: o, telegram: telegram}
     //       UpdateUserProfile({variables: {id: userProfile?.id, input : input}})
        }
    }

    useEffect(() => {
        const breadCrumbItems = {
            title: "Личный кабинет",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    
    var { users, user, LoadUser, error: userError, LoadUsers, RegisterUser, LoginUser, TestPhone, UpdateUserProfile, UserChangePasswordByPassword, ConfirmEmail } = useUsers({
        onDone: (operation, data) => {
          if (operation == "login")
          {
              localStorage.setItem("tocken", data.tocken)
              localStorage.setItem("authUser", JSON.stringify(data));
              document.location = "/"
          }

          if (operation == "updateprofile")
          {
            LoadUser({variables: {id : userProfile?.id ?? ""}})
            setNotify({ message: "Профиль обновился!", type: 'success' })
          }

          if (operation == "passwordchanged")
          {
            setNotify({ message: "Пароль успешно изменен!", type: 'success' })
          }

          if (operation == "sendconfirm")
          {
            setNotify({ message: "На почту отправлен запрос для подтверждения!", type: 'success' })
          }
        }
    })

    const [Countries] = useLazyQuery(graphql.queries.CountriesList,
        {
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            SetCountries(data.countries)
          },
          onError: (error) => {
            alert(error.message);
          },
        }
      );

    useEffect(()=>{
        Countries({})
        LoadUser({variables: {id : userProfile?.id ?? ""}})
    }, [userProfile])  

    useEffect(()=>{
        SetEmail(user?.email ?? "")
        SetTelegram(user?.telegram ?? "")
        SetPhone(user?.phone ?? "")
    }, [user])

    useEffect(()=>{
        if (userError!="")
        {
            setNotify({ message: userError, type: 'danger' })
        }
    }, [userError])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col md={12} lg={12} xl={6}>
                            <Card className="mb-0">
                                <CardBody>
                                    <button className={"btn waves-effect waves-light " + (page==1 ? "btn-primary" : "btn-outline-primary")} type="button" onClick={()=>SetPage(1)}>{language["Org account"]}</button>
                                    <button className={"btn mx-2 waves-effect waves-light " + (page==2 ? "btn-primary" : "btn-outline-primary")} type="button" onClick={()=>SetPage(2)}>{language["Requisites"]}</button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {page == 1 ?
                    <Row>
                        <Col md={12} lg={12} xl={6}>
                                        <div className=" d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">

                                                                <div className="mb-3 text-center">
                                                                    <button className="btn btn-primary waves-effect waves-light" type="button" onClick={AddNewOrg}>{language["Add org"]}</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                        </Col>
                    </Row> : <></>}

                    {page == 2 ?
                    <Row>
                        <Col md={12} lg={12} xl={6}>
                                                <div className=" d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">

                                                                <form className="mt-4 pt-2" action="/index">

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["OrgFullName"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["OrgFullName"]} defaultValue="" value={orgName} onChange={e => SetOrgName(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["INN"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["INN"]} defaultValue="" value={inn} onChange={e => SetInn(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["OGRN"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["OGRN"]} defaultValue="" value={ogrn} onChange={e => SetOgrn(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["KPP"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["KPP"]} defaultValue="" value={kpp} onChange={e => SetKpp(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["JurAddress"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["JurAddress"]} defaultValue="" value={JurAddress} onChange={e => SetJurAddress(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["FizAddress"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["FizAddress"]} defaultValue="" value={FizAddress} onChange={e => SetFizAddress(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["BankName"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["BankName"]} defaultValue="" value={bankName} onChange={e => SetBankName(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["BIK"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["BIK"]} defaultValue="" value={bik} onChange={e => SetBik(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>


                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["CorrSchet"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["CorrSchet"]} defaultValue="" value={cSchet} onChange={e => SetCSchet(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["RasSchet"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["RasSchet"]} defaultValue="" value={rSchet} onChange={e => SetRSchet(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["Phone number"]}</label>
                                                                                <InputMask
                                                                                    id="input-phone"
                                                                                    mask={phoneMask}
                                                                                    value={phone}
                                                                                    placeholder={phoneMask}
                                                                                    className="form-control input-color"
                                                                                    onChange={(e) => SetPhone(e.target.value)}>
                                                                                </InputMask>
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >Email</label>
                                                                                <input type={"email"} className="form-control m-0 pe-5" id="password-input" placeholder={""} defaultValue="" value={email} onChange={e => SetEmail(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                        <Row>
                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["EmailBuh"]}</label>
                                                                                <input type={"email"} className="form-control m-0 pe-5" id="password-input" placeholder={""} defaultValue="" value={bemail} onChange={e => SetBEmail(e.target.value)} />
                                                                            </Col>

                                                                            <Col sm={12} md={12} lg={6} xl={6} style={{marginBottom:20}}>
                                                                                <label >{language["FIORuk"]}</label>
                                                                                <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={""} defaultValue="" value={fio} onChange={e => SetFio(e.target.value)} />
                                                                            </Col>

                                                                        </Row>
                                                                </div>

                                                                    <div className="mb-3 text-center">
                                                                        <button className="btn btn-primary waves-effect waves-light" type="button" onClick={SaveOrgData}>{language["Save"]}</button>
                                                                    </div>
                                                                  
                                                                </form>

                                                              
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            
                        </Col>
                    </Row> : <></>}


                </Container>
            </div>
        </React.Fragment>
    );
};

export default Jurface;

