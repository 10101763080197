import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";


const FaqsPage = () => {
    const dispatch = useDispatch();

    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "FAQs",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);
    return (
        <React.Fragment>
            <MetaTags>
                <title>FAQs | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Часто задаваемые вопросы?</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">01.</h5>
                                                    <h5 className="faq-title mt-3">Куда писать, если у меня проблема?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Можно написать в телеграм: <a href="https://t.me/timelist_talk_bot" target='_blank'>@timelist_talk_bot.</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">02.</h5>
                                                    <h5 className="faq-title mt-3">Как можно использовать?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Можно использовать веб-сервис Таймлист Лайт, либо воспользоваться легкой интеграцией по API. Доступна On-premis установка на сервера компании.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">03.</h5>
                                                    <h5 className="faq-title mt-3">Кто может воспользоваться сервисом?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Таймлист могут использовать как организации, так и физические лица.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">04.</h5>
                                                    <h5 className="faq-title mt-3">Какие аудио и видео-форматы можно загружать для расшифровки?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Любые видео- и аудиоформаты, включая mp4, MOV, AVI, webm, ogg, mpeg, mp3, m4a и др.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">05.</h5>
                                                    <h5 className="faq-title mt-3">Какие языки распознает Таймлист AI?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Таймлист AI специализируется на расшифровке разговоров на русском языке, но умеет распознавать аббревиатуры и англицизмы.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">06.</h5>
                                                    <h5 className="faq-title mt-3">Как защищены аудио-файлы, кто имеет к ним доступ?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">Распознавание речи построено на технологиях искусственного интеллекта и полностью автоматизировано. У сотрудников Таймлист и третьих лиц нет доступа к вашим аудиофайлам.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4">
                                            <div className="card bg-light overflow-hidden">
                                                <div className="card-body">
                                                    <div className="faq-icon">
                                                        <i className="bx bx-help-circle text-primary"></i>
                                                    </div>
                                                    <h5 className="text-primary">07.</h5>
                                                    <h5 className="faq-title mt-3">Где хранятся записи?</h5>
                                                    <p className="faq-ans text-muted mt-2 mb-0">На российских серверах, соответствующих законодательству РФ, которые имеют 1-ый уровень защищенности данных и SLA на работу сервисов 99,95%.</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaqsPage;