import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from "prop-types";

//vertical Layout component
import Header from './Header';
import Sidebar from './Sidebar';
import RightSidebar from "../../components/Common/RightSidebar";
import { withRouter } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";
import {usePresets} from "../../Hooks/PresetsHooks"

//actions
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
    showRightSidebarAction,
    changelayoutMode
} from "../../store/actions";

const Layout = (props) => {
    const dispatch = useDispatch();
    const {
        breadCrumbItems,
        layoutWidth,
        leftSideBarType,
        topbarTheme,
        showRightSidebar,
        leftSideBarTheme,
        layoutMode,
        balance,
        showSearchBar,
        showUploaderBar
    } = useSelector((state) => ({
        breadCrumbItems: state.Breadcrumb.breadCrumbItems,
        leftSideBarType: state.Layout.leftSideBarType,
        layoutWidth: state.Layout.layoutWidth,
        topbarTheme: state.Layout.topbarTheme,
        showRightSidebar: state.Layout.showRightSidebar,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        layoutMode: state.Layout.layoutMode,
        balance: state.Breadcrumb.balance,
        showSearchBar: state.Layout.showSearchBar,
        showUploaderBar: state.Layout.showUploaderBar
    }));

    var [minutecost, SetMinuteCost] = useState(0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMenuCallback = () => {
        if (leftSideBarType === "default") {
            dispatch(changeSidebarType("condensed", isMobile));
        } else if (leftSideBarType === "condensed") {
            dispatch(changeSidebarType("default", isMobile));
        }
    };

    //hides right sidebar on body click
    const hideRightbar = useCallback((event) => {
        var rightbar = document.getElementById("right-bar");
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return;
        } else {
            //if clicked in outside of rightbar then fire action for hide rightbar
            dispatch(showRightSidebarAction(false));
        }
    }, [dispatch]);

    const { error: presetserror, LoadPresets } = usePresets()

    /*
  layout  settings
  */

    useEffect(() => {
        //init body click event fot toggle rightbar
        document.body.addEventListener("click", hideRightbar, true);
    }, [hideRightbar]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (layoutMode) {
            dispatch(changelayoutMode(layoutMode));
        }
    }, [leftSideBarTheme, layoutMode, dispatch]);

    useEffect(() => {
        dispatch(changeLayout("vertical"));
    }, [dispatch]);

    useEffect(() => {
        if (leftSideBarTheme) {
            dispatch(changeSidebarTheme(leftSideBarTheme));
        }
    }, [leftSideBarTheme, dispatch]);

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth));
        }
    }, [layoutWidth, dispatch]);

    useEffect(() => {
        if (leftSideBarType) {
            dispatch(changeSidebarType(leftSideBarType));
        }
    }, [leftSideBarType, dispatch]);

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [topbarTheme, dispatch]);

    /*
    document title
    */
    useEffect(() => {
        const title = props.location.pathname;
        let currentage = title.charAt(1).toUpperCase() + title.slice(2);

        document.title = "Таймлист - ИИ расшифровки аудио и автопротоколирования.";
    }, [props.location.pathname]);


    useEffect(() => {
        window.scrollTo(0, 0);
        LoadPresets({ variables: { names: ['minutecost'] } })
          .then(( { data } ) => {
              const minuteCost = data?.presetslist?.filter(item => item.name && item.name === 'minutecost')
              minuteCost?.length && SetMinuteCost(parseFloat(minuteCost[0].value))
          })
    }, [LoadPresets]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header breadCrumbItems={breadCrumbItems} balance={balance} showSearchBar={showSearchBar} showUploaderBar={showUploaderBar} toggleMenuCallback={toggleMenuCallback} minutecost={minutecost} />

                <Sidebar
                    theme={leftSideBarTheme}
                    type={leftSideBarType}
                    isMobile={isMobile}
                />

                <div className="main-content">
                    {props.children}
                </div>
            </div>
            {showRightSidebar ? <RightSidebar /> : null}
        </React.Fragment>
    );
};

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarThemeImage: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarThemeImage: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
