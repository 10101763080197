import React, { useCallback, useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//import { appContext } from "../../App";
import {TranslateContext} from "../../providers/Translate";
import {useOrg} from "../../Hooks/OrgHooks"
const SidebarContent = (props) => {
    //const context = useContext(appContext)
    const {language} = React.useContext(TranslateContext)

    const [personal, SetExpandPersonal]= useState(false)
    const [admin, SetAdmin] = useState(false)
    const ref = useRef();

    const {LoadOrg, orgdata, error} = useOrg({onDone: (type, data) => {
        if (type == "orgdata")
        {
            //data?.id ?? ""
        }
    }})

    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }
    };

    const activeMenu = useCallback(() => {
        const pathName = props.location.pathname;
        const fullPath = pathName;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (fullPath === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [props.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
        activeMenu();

        var userdata = JSON.parse(localStorage.getItem("authUser"))
        SetAdmin(userdata?.phone == "79220763509")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        activeMenu();
    }, [activeMenu]);

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }
    return (
        <React.Fragment>
            <SimpleBar className="sidebar-menu-scroll" ref={ref}>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title" data-key="t-menu">Меню</li>

                        <li>
                            <Link to="/tasks">
                                {/* <i className="icon nav-icon" data-eva="calendar-outline"></i> */}
                                <span className="menu-item" data-key="t-calendar">{language["My files"]}</span>
                            </Link>
                        </li>


                        {orgdata ? <>

                        <li>
                            <Link to="/contacts">
                                <span className="menu-item" data-key="t-calendar">{language["Contacts"]}</span>
                            </Link>
                        </li> </> : <></> }

                        <li>
                            <Link to="/terms">
                                {/* <i className="icon nav-icon" data-eva="calendar-outline"></i> */}
                                <span className="menu-item" data-key="t-calendar">{language["Terms"]}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to={""} onClick={()=>{SetExpandPersonal(!personal)}}>
                                <span className="menu-item w-100" data-key="t-calendar">{language["Personal cabinet"]}</span>
                                {personal ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
                            </Link>
                        </li>

                        {personal ? <>
                            <li style={{paddingLeft:20}}>
                                <Link to="/profile">
                                    <span className="menu-item" data-key="t-calendar">{language["Profile"]}</span>
                                </Link>
                            </li> 
                            <li style={{paddingLeft:20}}>

                            {admin ?
                            <li>
                                <Link to="/jurface">
                                    <span className="menu-item" data-key="t-calendar">{language["Jurface"]}</span>
                                </Link>
                            </li>  : <></>}
                            
                            <li style={{paddingLeft:20}}></li>
                            
                            <a href="https://t.me/timelist_talk_bot" target="_blank">
                                <span className="menu-item" data-key="t-calendar">{language["Pay"]}</span>
                            </a>
                            </li>
                        </> : <></>}

                        <li>
                            <Link to="/feedback">
                                {/* <i className="icon nav-icon" data-eva="calendar-outline"></i> */}
                                <span className="menu-item" data-key="t-calendar">{language["Feedback"]}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/faq">
                                {/* <i className="icon nav-icon" data-eva="calendar-outline"></i> */}
                                <span className="menu-item" data-key="t-calendar">{language["FAQ"]}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/auth-logout">
                                {/* <i className="icon nav-icon" data-eva="calendar-outline"></i> */}
                                <span className="menu-item" data-key="t-calendar">{language["Exit"]}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};
SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};
export default withRouter((SidebarContent));
