// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_LAYOUT_THEME,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_LAYOUT_POSITION,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SHOW_SEARCH_BAR,
  SHOW_UPLOADER_BAR
} from "./actionType";

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  layoutModeTypes
} from "../../components/constants/layout";

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  layoutMode: layoutModeTypes.LIGHTMODE,
  layoutPosition: "false",
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showUploaderBar: false,
  showSearchBar: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case CHANGE_LAYOUT_POSITION:
      return {
        ...state,
        layoutPosition: action.payload,
      };
    case CHANGE_LAYOUT_THEME:
      return {
        ...state,
        layoutMode: action.payload,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      };
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      };
    case SHOW_SEARCH_BAR:
        return {
          ...state,
          showSearchBar: action.payload,
        };
    case SHOW_UPLOADER_BAR:
          return {
            ...state,
            showUploaderBar: action.payload,
          };
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
