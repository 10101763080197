import React from "react";
import {createContext} from "react";

export const NotifyContext = createContext({})

export const InformNotify = ({notification, removeNotification}) => {
//type = primary secondary success danger warning info

  React.useEffect(() => {
    const timer = setTimeout(() => removeNotification(), 3000)
    return () => clearTimeout(timer)
  }, [notification, removeNotification])

  if (!notification) return (<></>)

  const style = {
    display: 'flex',
    position: 'fixed',
    zIndex: 9999,
    top: '20px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  }

  return (
    <div style={style}>
      <div className={`alert alert-${notification.type}`} role="alert">
        {notification.message}
      </div>
    </div>
  );
}

export const NotifyProvider = ({children}) => {
  const [notification, setNotification] = React.useState(null)
  const setNotify = ( notify ) => setNotification(notify)

  return (
    <NotifyContext.Provider value={ { setNotify } }>
      <InformNotify
        notification={notification}
        removeNotification={() => setNotification(null)}
      />
      {children}
    </NotifyContext.Provider>
  );
};
