
import React, { useEffect, useContext } from 'react';
import MetaTags from "react-meta-tags";
// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AuthSlider from './authCarousel';
import {TranslateContext} from "../../providers/Translate";

const Logout = () => {
    const {language} = React.useContext(TranslateContext)

    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }

        localStorage.removeItem("authUser");
        localStorage.removeItem("tocken")

        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });
    return (
        <React.Fragment>
            <MetaTags>
            <title>Выход | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="p-0 auth-bg">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <div className="avatar-lg mx-auto">
                                                                        <div className="avatar-title bg-light text-primary h1 rounded-circle">
                                                                            <i className="bx bxs-user"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-4 pt-2">
                                                                        <h5>{language["You are Logged Out"]}</h5>
                                                                        <div className="mt-4">
                                                                            <Link to="/login" className="btn btn-primary w-100 waves-effect waves-light">{language["Sign In"]}</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-4 text-center">
                                                                    <p className="text-muted mb-0"><Link to="/auth-register"
                                                                        className="text-dark text-decoration-underline">{language["Register"]}</Link> </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Logout;
