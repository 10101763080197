import React, {useContext} from "react";
import { Col, Row } from "reactstrap";
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import login from "../../assets/images/login-img.png";
import logo from "../../assets/images/logo.png";
import {TranslateContext} from "../../providers/Translate";

const AuthSlider = () => {
  const {language} = React.useContext(TranslateContext)

  return (
    <React.Fragment>
      <Col className="col-xxl-7 col-lg-7 col-md-7">
        <div className="d-flex" style={{height:"90vh"}}>
          <Row className="justify-content-center align-items-center">
            <Col xl={10}>
              <div className="testi-contain text-center">
                <img src={logo} style={{width:"276px", height:"56px"}} className="" alt="" />
              </div>
              <div className="mt-4">
                <img src={login} className="img-fluid" alt="" />
              </div>
              <div className="p-0 p-sm-4 px-xl-0 py-5">
              <div className="carousel-inner w-75 mx-auto">


                    <div>
                      <div className="item">
                        <div className="testi-contain text-center">
                          <h4 className="font-size-20 mt-4" style={{color:"#1A2F7F"}}>{language["From words to deal"]}</h4>
                        </div>
                      </div>
                    </div>

                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>

    </React.Fragment>
  );
};

export default AuthSlider;
