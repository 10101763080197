import React from 'react';
import Footer from './Footer';
import SidebarContent from './SidebarContent';
import { Link } from "react-router-dom";

//Import Logo images
import logoDark from "../../assets/images/logo-dark.png";
import logoDarkSm from "../../assets/images/logo-dark-sm.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSm from "../../assets/images/logo-light-sm.png";
import logoCabinet from "../../assets/images/logo.png";

import { connect } from "react-redux";
import { changeSidebarType } from "../../store/actions";

const Sidebar = (props) => {

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
            body.classList.remove("right-bar-enabled");
        } else {
            body.classList.toggle("sidebar-enable");
            body.classList.toggle("vertical-collpsed");
            body.classList.remove("right-bar-enabled");
            body.setAttribute("data-sidebar-size", "sm");
        }
    }

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logoDarkSm} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoCabinet} alt="" height="22" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-lg">
                            <img src={logoCabinet} alt="" height="22" />
                        </span>
                        <span className="logo-sm">
                            <img src={logoLightSm} alt="" height="22" />
                        </span>
                    </Link>
                </div>

                <button type="button" className="btn btn-sm px-3 header-item vertical-menu-btn topnav-hamburger" onClick={() => tToggle()}>
                    <div className="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>

                <SidebarContent />

                <Footer />
            </div>
        </React.Fragment>
    );
};

// export default Sidebar;

const mapStateToProps = state => {
    return { ...state.Layout };
};

export default connect(mapStateToProps, {

    changeSidebarType,

})(Sidebar);

