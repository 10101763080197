import React from "react";
import s from './Textarea.module.css'

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

//import { ImageResize } from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);

import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //['blockquote', 'code-block'],
  ['link', 'image', 'video'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //[{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  //[{ 'align': [] }],

  ['clean']                                         // remove formatting button
];

let fullscreenEditor = false

export const TextareaAutoNew = ( { value, rowindex, speaker, onChange, isBold, tab, searchtext,
  selectedkey,
  SetFocusedKey,
  OnAddParagraph,
  OnRemoveParagraph,
  OnSaveData
 } ) => {     // do not remove "tab". It's need to resize textarea
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const hiddenElement = React.useRef(null);
  const [height, setHeight] = React.useState(0)
  const [focused, SetFocused] = React.useState(false)
  const [fullscreen, SetFullscreen] = React.useState(false)
  const [visibletext, SetVisibleText] = React.useState(value ?? "")
  const [originaltext, SetOriginalText] = React.useState(value ?? "")
  const [border, SetBorder] = React.useState("0px solid black")

  function adjustHeight(el){
    if (el)
    {
        el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight)+"px" : "auto";
    }
  }

  const HightLightFileText = (text, substr) => {
    let rg = new RegExp(substr, 'gi');
    return substr!="" ? text.replace(rg, `<span style="color:red; font-weight:bold;">${substr.toUpperCase()}</span>`) : text
  }

  function unEscape(htmlStr) {
    htmlStr = htmlStr ? htmlStr : ""
    htmlStr = htmlStr.replace(/&lt;/g , "<");	 
    htmlStr = htmlStr.replace(/&gt;/g , ">");     
    htmlStr = htmlStr.replace(/&quot;/g , "\"");  
    htmlStr = htmlStr.replace(/&#39;/g , "\'");   
    htmlStr = htmlStr.replace(/&amp;/g , "&");
    return htmlStr;
  }

  React.useEffect(() => 
  {
    //setHeight(hiddenElement.current.clientHeight)
    adjustHeight(ref.current)
    //adjustHeight(ref2.current)
    //Quill.register('modules/imageResize', ImageResize);
  }, [tab])

  React.useEffect(() => {
    const textareaRef = ref.current
    if (!ref && !textareaRef) return

    const autoResize = () => setTimeout(() => {
      //textareaRef.style.cssText = 'height:auto;'
      //textareaRef.style.cssText = `height: ${textareaRef.scrollHeight}px`
    }, 0)

    const keyHandler = (e) => {
      if (e.key === 'Escape' && !e.shiftKey) {
        e.preventDefault()
        if (fullscreenEditor == true)
        {
          SetFullscreen(false)
          fullscreenEditor = false
        } else 
        {
          SetFocused(false)
          SetFocusedKey("")
        }
      }
    }



    ref?.current?.addEventListener('input', autoResize);
    document.addEventListener('keyup', keyHandler);
   

    return () => {
      textareaRef?.removeEventListener('input', autoResize)
      document.removeEventListener('keyup', keyHandler)
      
    }
  }, [height, tab])

  React.useEffect(()=>{
    let copytext = value ?? ""
    let highlitedText = searchtext!="" ? copytext.replace(searchtext, `<b>${searchtext}</b>`) : (value ?? "")
    SetVisibleText(highlitedText)
  }, [searchtext])

  React.useEffect(()=>{
      SetFocused(selectedkey == `editor_${rowindex}`)
  }, [selectedkey])

  React.useEffect(()=>{

    const mouseKeyHandler = (e) => {
      if (e.which == 1)
      {
        SetFocused(false)
        SetFocusedKey("")
      }
    }

    const mouseKeyHandler2 = (e) => {
      if (e.which == 1)
      {
        e.stopPropagation()
        e.preventDefault()
      }
    }

    const closEditor = (e) => {
      //if (confirm("Сохранить изменения в редакторе?"))
      //{
        //if (OnSaveData) OnSaveData()
      //}
      SetFocused(false)
      SetFocusedKey("")
    }

    let root = document.getElementById("root")
    let editorContainer = document.getElementsByClassName("editorContainer")[0]

    if (focused)
    {
      document.addEventListener('closeditor', closEditor)
    }
    else 
    {
      document.removeEventListener('closeditor', closEditor)
    }

    return () => {
      document.removeEventListener('closeditor', closEditor)
    }
  }, [focused])

  React.useEffect(()=>{
    if (value?.indexOf("<p>")<0)
    {
      let preparedText = value.split("\n").reduce((alldata, p)=>{
          return alldata + `<p>${p}</p>`
      }, "")
      SetVisibleText(preparedText)//(value.replace(/[\n]/ig, "<br>"))  
    } else 
    {
      SetVisibleText(value ?? "")
    }
  }, [value])

  
  return (
    <div className={ (fullscreen ? s.wrapperfullscreen : s.wrapper) + " editorContainer" } key={`editor_${rowindex}`}>

      {!focused ?
      <div ref={ ref } style={{width:"100%", minHeight:30, paddingRight: 60, border: visibletext?.trim().replace(/<[^>]*>?/gm, '') == "" ? "1px dashed lightgray" : ""}} 
      className={ `${s.textarea_auto} ${isBold ? s.bold : ''}` } onClick={()=> {
          SetFocusedKey(`editor_${rowindex}`)
      }} dangerouslySetInnerHTML={{__html: unEscape(HightLightFileText(visibletext, searchtext))}}>
      </div>
      :
      <>
        {!fullscreen ?  <div className="d-flex justify-content-center position-relative" style={{height:30, marginBottom:10}}>
                     <button
                      id = {`addbtn_${rowindex}`}
                      className='btn btn-sm btn-outline-secondary'
                      onClick={ () => {
                        OnAddParagraph(rowindex, "before")
                        SetFocusedKey(`editor_${parseInt(rowindex)+1}`)
                      }}
                    >
                        <i class="fas fa-plus"></i> Вставить параграф выше
                    </button>

          </div> : <></>}

      <ReactQuill theme="snow" 
        //className={!focused ? 'hidden-toolbar' : ''}
        modules = {{ toolbar : toolbarOptions,   imageResize: {
          parchment: Quill.import('parchment'),
          modules: ['Resize', 'DisplaySize']
       } }} key={`editor_${rowindex}`} 
        style={{background: isBold ? "#f7f7f7" : "", paddingRight: 60, height: fullscreen ? "95vh" : "auto"}} value={visibletext} 
        onFocus={ (range, source, editor) => {
          //SetFocused(true)
          
        }}
        onBlur={ (previousRange, source, editor) => {
         // SetFocused(false)
        }}
        onChange={(text)=>{
         SetVisibleText(text)
          onChange(text)
        }} />

     
              {!fullscreen ? <div className="d-flex justify-content-center position-relative" style={{height:30, marginTop:10}}>
                     <button
                      id = {`addbtn_${rowindex}`}
                      className='btn btn-sm btn-outline-secondary'
                      onClick={ () => {OnAddParagraph(rowindex, "after")} }
                    >
                        <i class="fas fa-plus"></i> Вставить параграф ниже
                    </button>

                 </div> : <></>}
      </>
    }

    {focused  ?
        <div className="d-flex flex-column justify-content-start position-absolute mt-2" style={{ right:15, top:0, zIndex: 100}}>

            <button
                title={"На полный экран"}
                id = {`fullscreenbtn_${rowindex}`}
                className='btn btn-sm btn-outline-secondary mb-2 stopMouseEvents'
                          onClick={ (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              SetFullscreen(!fullscreen)
                              fullscreenEditor = !fullscreenEditor
                          } }
              >
                <i class={fullscreen ? "fa fa-compress" : "fa fa-expand"}></i>
            </button>

            <button
                title={"Удалить параграф"}
                id = {`removebtn_${rowindex}`}
                className='btn btn-sm btn-outline-secondary stopMouseEvents'
                          onClick={ (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            let result = confirm("Удалить параграф?");
                            if (result)
                            {
                              OnRemoveParagraph(rowindex)
                            }
                          } }
              >
                <i class="fas fa-trash"></i>
            </button> 
       
        </div>
        : null }

    </div>
  )
}
