import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from "react-router-dom";

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import Icon from 'react-eva-icons';
import MetaTags from "react-meta-tags";
import InputMask from "react-input-mask";

import graphql from "../../graphql/gql"

// redux
import { useSelector } from "react-redux";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, UncontrolledAlert } from 'reactstrap';

import AuthSlider from './authCarousel';

import { useUsers } from "../../Hooks/UserHooks";
//import { appContext } from "../../App";
import {TranslateContext} from "../../providers/Translate";

var startawait = null
const RecoverPassword = () => {
    let history = useHistory();

    const {language} = React.useContext(TranslateContext)

    var [phone, SetPhone] = useState("");
    var [errors, SetError] = useState([]);
    var [phoneForCall, SetPhoneForCall] = useState("")
    var [passchange, SetPassChange] = useState(false)
    var [passview, SetPassView] = useState(false);
    var [password, SetPassword] = useState("")

    const { layoutMode } = useSelector((state) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    const TogglePasswordInput = () => {
        SetPassView(!passview)
    }

    const TryRegister = () => {
        var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
        UserChangePassword({
          variables: { phone: clearphone, password: password },
        });
      };

    const TryRegisterUser = () =>{

        if (password.length<8)
        {
          SetError([...errors, "Пароль слишком короткий или отсутствует."])
          setTimeout(() => {
              SetError([])
          }, 2000)
        } else 
        {
          TryRegister()
        }
      }

    const [PhoneHasCallFrom] = useLazyQuery(graphql.queries.phoneConfirmationHasCall,
        {
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            if (data.hasawaitedcall && data.hasawaitedcall.length>0)
            {
              if (data.hasawaitedcall[0].callback == 1)
              {
                  clearInterval(startawait)
                  SetPassChange(true)
              }
            }
          },
          onError: (error) => {
            alert(error.message);
          },
        }
      );

    const [PhoneConfirmation] = useMutation(graphql.mutations.sendPhoneConfirmationMutation,
        {
          onCompleted: (data) => {
            clearInterval(startawait)
            //var testphone = "8" + data.awaitcall.substring(1)
            SetPhoneForCall("+"+data.awaitcall)
    
            var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
            startawait = setInterval(()=>
            {
              PhoneHasCallFrom({variables:{phone:clearphone}})
            }, 5000)
          },
          onError: (error) => {
            alert(error.message);
          },
        }
      );

    var { users, error: userError, LoadUsers, RegisterUser, LoginUser, TestPhone, UserChangePassword } = useUsers({
        onDone: (operation, data) => {
            if (operation == "passwordchanged")
            {
                alert("Пароль успешно изменен!")
                history.push("/login");
            }

            if (operation == "testphone")
            {
              if (data == true)
              {
                // номер не существует, не могу для него менять пароль
                SetError(["Номер не зарегистрирован! Попробуйте другой."])
                setTimeout(() => {
                  SetError([])
                }, 2000)
              } else 
              {
                // номер существует, делаю на него прозвон и ввод нового пароля
                var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
                SetPassChange(false)
                PhoneConfirmation({variables:{phone: clearphone}})
              }
            }
        }
    })

    useEffect(() => {
        if (layoutMode === "dark") {
            document.body.classList.remove("bg-transparent");
        } else {

            document.body.className = "bg-transparent";
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    const ConfirmClick = () => {
        var err = []
        var clearphone = phone.replace(/[_,\-,+,(,), ]/g, "")
        
        if (clearphone == "" || clearphone.length < 11)
        {
            err.push("Введите номер телефона.\nВсе цифры должны быть заполнены.")
        }
    
        if (err.length>0)
        {
            SetError([...errors, ...err])
            setTimeout(() => {
                SetError([])
            }, 2000)
            return
        }
    
        // тест телефона
        TestPhone({variables: {phone: clearphone}})
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Восстановление пароля | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="auth-page">
                <Container fluid className="py-md-5 p-4 auth-bg">
                    <Row className="g-0 align-items-center">
                        <Col className="col-xxl-4 col-lg-4 col-md-6">
                            <Row className="justify-content-center g-0">
                                <Col xl={9}>
                                    <div className="p-4">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                    <div className="w-100">
                                                        <div className="d-flex flex-column h-100">

                                                            <div className="auth-content my-auto">
                                                                <div className="text-center">
                                                                    <h5 className="mb-0 fw-semibold">{language["Restore password"]}</h5>
                                                                </div>
                                                                <div className="text-center my-4 font-size-12" role="alert">
                                                                    {phoneForCall != "" ? language["callawait"] : language["Enter your phone"]}
                                                                </div>

                                                                {errors.map((err)=>{
                                                                    return <UncontrolledAlert color="danger">
                                                                        {err.split("\n").map((e)=>{
                                                                            return <div>{e}</div>
                                                                        })}
                                                                    </UncontrolledAlert>
                                                                })}

                                                                <form className="mt-4 pt-2" action="/">
                                                                    
                                                                        <label htmlFor="input-username">
                                                                        {passchange ? language["Password"] : language["Phone"]}
                                                                        </label>
                                                                        
                                                                        {passchange ? 
                                                                        <div className=" mb-4 auth-pass-inputgroup">
                                                                        <div className='d-flex align-items-center position-relative'>
                                                                        <input type={passview ? "text" : "password"} className="form-control pe-5" id="password-input" placeholder={language["Enter Password"]} value={password} onChange={e => SetPassword(e.target.value)} />
                                                                        <button type="button" className="btn btn-link position-absolute m-0" style={{right:0,top:2}} id="password-addon" onClick={TogglePasswordInput}>
                                                                            {passview ? <i className="font-size-18 text-muted fas fa-eye"></i> :
                                                                                        <i className="font-size-18 text-muted fas fa-eye-slash"></i>}
                                                                        </button>
                                                                        </div>
                                                                        </div> : <>
                                                                        {phoneForCall == "" ?
                                                                            <div className="mb-4">
                                                                            <InputMask
                                                                                id="input-restorephone"
                                                                                mask="+7 (999) 999 99 99"
                                                                                value={phone}
                                                                                placeholder="+7 (XXX) XXX XX XX"
                                                                                className="form-control input-color"
                                                                                onChange={(e) => SetPhone(e.target.value)}
                                                                            >
                                                                            </InputMask>
                                                                            </div> : <a href={`tel: ${phoneForCall}`}><h3 className="mt-5 mb-5 text-center text-primary font-weight-bold">{phoneForCall}</h3></a>}
                                                                        </>}
                                                                    
                                                                    <div className="mb-3">
                                                                        {passchange ? <button className="btn btn-primary w-100 waves-effect waves-light" type="button" onClick={()=>TryRegisterUser()}>{language["Confirm"]}</button> :
                                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="button" onClick={()=>ConfirmClick()}>{language["Confirm"]}</button>}
                                                                    </div>
                                                                </form>

                                                                <div className="mt-4 text-center">
                                                                    <p className="text-muted mb-0"><Link to="/auth-register"
                                                                        className="text-dark text-underline-on-hover">{language["Register"]}</Link> </p>
                                                                </div>

                                                                <div className="mb-4 text-center">
                                                                    <p className="text-muted mb-0">{language["Remember it"]}?  <Link to="/login"
                                                                        className="text-dark text-underline-on-hover"> {language["Back"]} </Link> </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <AuthSlider />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default RecoverPassword;